import React, { useState, useEffect } from "react";
import './CallStyle.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import useRemoteStream from '../../hooks/useRemoteStream';
import usePeerV2 from '../../hooks/usePeerV2';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import CryptoJS from 'crypto-js';
import RemoteStream from '../Call/RemoteStream';

const MonitoringV3 = ({ startDetection, stopDetection, isCountingDown, isDone, onComputeHeartRate }) => {
    let pageLogin = name || window.location.href.split("?")[1];
    const [remoteStream, addRemoteStream, removeRemoteStream] = useRemoteStream();
    const [peer, peerConnected] = usePeerV2(addRemoteStream, removeRemoteStream);
    const [headPosition, setHeadPosition] = useState({ horizontal: 0, vertical: 15 });
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [remoteId, setRemoteId] = useState(pageLogin);
    const [call, setCall] = useState(null);
    const [conn, setConn] = useState(null);

    // Create a fake local media stream
    const createMediaStreamFake = () => {
        return new MediaStream([createEmptyAudioTrack(), createEmptyVideoTrack({ width: 320, height: 240 })]);
    };

    const createEmptyAudioTrack = () => {
        const ctx = new AudioContext();
        const oscillator = ctx.createOscillator();
        const dst = oscillator.connect(ctx.createMediaStreamDestination());
        oscillator.start();
        oscillator.mute = true;
        return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
    };

    const createEmptyVideoTrack = ({ width, height }) => {
        const canvas = Object.assign(document.createElement('canvas'), { width, height });
        canvas.getContext('2d').fillRect(0, 0, width, height);
        return Object.assign(canvas.captureStream().getVideoTracks()[0], { enabled: false });
    };

    const localstream = createMediaStreamFake();

    useEffect(() => {
        if (peer != null) {
            callUser();
        }
    }, [peerConnected]);

    const openConnection = () => {
        const thisConnection = peer.connect(remoteId);
        setConn(thisConnection);

        thisConnection.on('open', () => {
            const randomString = generateRandomString(8);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }), randomString).toString();
            thisConnection.send(`${encryptedData}+++${randomString}`);
        });

        thisConnection.on('data', (data) => {
            const parsedMessage = JSON.parse(data);
            if (parsedMessage?.type === 'photo') downloadImage(parsedMessage.data, 'image.jpg');
        });
    };

    const generateRandomString = (length) => {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        return Array.from({ length }, () => charset.charAt(Math.floor(Math.random() * charset.length))).join('');
    };

    const callUser = () => {
        const api = `{"api":"echoCancel", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => sendMessage(api));

        setIsConnecting(true);
        openConnection();

        const thisCall = peer.call(remoteId, localstream, { constraints: { 'mandatory': { 'OfferToReceiveAudio': false, 'OfferToReceiveVideo': true } } });
        setCall(thisCall);

        thisCall.on('stream', (stream) => {
            addRemoteStream(stream);
            setIsConnecting(false);
            setIsConnected(true);
        });

        thisCall.on('close', endCall);
    };

    const endCall = () => {
        if (call) {
            call.close();
            setCall(null);
        }
    };

    const downloadImage = (base64Data, fileName) => {
        const linkSource = `data:image/jpg;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    //The below functions are for controlling the Robot Movements 
    const sendMessage2 = async (message) => {
        console.log('Sending message: ', message);
        if (conn === null) {
            return;
        }
        conn.send(JSON.stringify(message));
    }

    const moveHead = (newHeadPosition) => {
        const message = Object.assign(newHeadPosition, { api: "head" });

        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";

        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            randomString += charset.charAt(randomIndex);
        }

        console.log("RANDOM STRING ", randomString);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(message), randomString).toString();
        conn.send(encryptedData.concat("+++").concat(randomString));
        setHeadPosition(newHeadPosition);
    }

    const moveHeadLeft = () => {
        if (headPosition.horizontal < 80) {
            const newHeadPosition = { horizontal: headPosition.horizontal + 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadRight = () => {
        if (headPosition.horizontal > -80) {
            const newHeadPosition = { horizontal: headPosition.horizontal - 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadUp = () => {
        console.log(headPosition);
        if (headPosition.vertical < 50) {
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical + 10 };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadDown = () => {
        if (headPosition.vertical > -50) {
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical - 10 };
            moveHead(newHeadPosition);
        }
    }

    const resetHead = () => {
        const newHeadPosition = { horizontal: 0, vertical: 15 };
        moveHead(newHeadPosition);
    }

    useEffect(() => {
        if (!isCountingDown) {
            endCall()
        }
    }, [isCountingDown]);

    return (
        <div style={styles.container}>
            <div style={styles.buttonContainer}>
                <button style={styles.button} onClick={() => { startDetection(); callUser(); }}>Start Detection</button>
                <button style={styles.button} onClick={() => { stopDetection(); endCall(); }}>Stop</button>
                {!isCountingDown && isDone && <button style={styles.button} onClick={onComputeHeartRate}>Get Heart Rate</button>}
                {isCountingDown && isDone && <button style={styles.button} onClick={onComputeHeartRate}>Retry</button>}
            </div>

            {
                isCountingDown && <div className="row">
                <div className='col-12'>
                    <div id="video-container" className={isConnected ? "" : "hidden"}>
                        <div id="controls-container" style={styles.controlsContainer}>
                            <div id='menu'>
                                <div className="keys">
                                    <button className="up arr" onClick={moveHeadUp}><i className="fa fa-arrow-up"></i>^</button>
                                    <br />
                                    <button className="left arr" onClick={moveHeadLeft}><i className="fa fa-arrow-left "></i>{'<'}</button>
                                    <button className="centre arr" onClick={resetHead}><i className="fa fa-dot-circle-o"></i></button>
                                    <button className="right arr" onClick={moveHeadRight}><i className="fa fa-arrow-right"></i>{'>'}</button>
                                    <br />
                                    <button className="down arr" onClick={moveHeadDown}><i className="fa fa-arrow-down"></i>{'v'}</button>
                                </div>
                            </div>
                            {/* Remote Stream with Overlay */}
                            <div id="remote-video" style={styles.videoContainer}>
                                <RemoteStream remoteStream={remoteStream} muteOrNot={true} style={styles.remoteStream} />
                                <div style={styles.overlayRectangle}></div> {/* Rectangle overlay */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

const styles = {
    container: { textAlign: "center", margin: "20px" },
    buttonContainer: { display: "flex", justifyContent: "center", gap: "10px", marginBottom: "20px" },
    button: { padding: "10px 20px", fontSize: "16px", border: "none", borderRadius: "5px", backgroundColor: "#4CAF50", color: "white", cursor: "pointer", transition: "background-color 0.3s" },
    controlsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    videoContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "70%", // Adjust width as desired
        height: "400px", // Set fixed height for the video container
        maxWidth: "800px", // Optional max width
        borderRadius: "10px",
        overflow: "hidden"
    },
    remoteStream: {
        height: "400px", // Set fixed height for the video container
        maxWidth: "800px", // Optional max width
        objectFit: "cover" // Maintain aspect ratio of the video
    },
    overlayRectangle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "30%",
        height: "80%",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
        transform: "translate(-50%, -50%)",
        border: "2px solid #ff0000",
        zIndex: 10
    }
};

export default MonitoringV3;
