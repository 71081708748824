import React, { Component, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Learning from "./Learning";
import AuthService from "../../services/auth.service";
import TitleBar from "../Common/TitleBar";
import Button from '@material-ui/core/Button';
import { sendMessage, socket, waitForSocketConnection, name } from '../../socket';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CastSelector from "../analytics/CastSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 150,
    padding: 5,
    margin: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      // zIndex: 1,
      // '& $imageBackdrop': {
      //   opacity: 0.15,
      // },
      // '& $imageMarked': {
      //   opacity: 0,
      // },
      '& $imageTitle': {
        border: '4px solid currentColor',
        borderRadius: '0.75rem!important',
        transition: ' 0.5s all',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#1D3557',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#fff',
    padding: '20px 10px!important',
    borderRadius: '0.75rem!important',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
    transition: ' 0.5s all',
    overflow: 'hidden',
    //transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    justifyContent: 'center',
    width: '100%',
    transition: ' 0.5s all',
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: '#1D3557',
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function LearningList() {

  const [selectquiz, setQuizId] = useState('');
  const [isSelected, setSelected] = useState(false);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [gotSongs, setGotSongs] = useState(false);

  useEffect(() => {
    let location = window.location.href.split("/")
    if (location.length == 6) {
      let robot = location[4]
      let selectTitle = location[5].replaceAll("%20", " ")
      console.log("enterjoanna ", robot, selectTitle)
      setSelected(true);
      setQuizId(selectTitle);
      localStorage.setItem("remoteId", robot)
    }
    console.log("enterjoanna ", location)
    let mylist = [];
    AuthService.getLearningData().then(
      (response) => {

        response.data.learning.map((item, i) => {
          mylist.push({
            url: '/images/button.png',
            title: item,
            width: '30%',
          });
        });

        setData(mylist);
        console.log(mylist);
      },
      (error) => {

      }
    )

    let name = localStorage.getItem('remoteId');
    let isMounted = false;
    // if (!name) {
    //   history.push('/home')
    // }
    if (!gotSongs) {
      // var api = `{"api":"getLearningList", "action":"get","name": "${name}"}`;
      // waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    socket.onmessage = (message) => {
      console.log("MESSAGE ", message.data)
      if ((message.data) && (message.data.toString().includes("learning"))) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log(str);
        try {
          var obj = JSON.parse(message.data);
          if (!isMounted) {
            console.log(obj);
            setGotSongs(true);
            let mylist = [];
            obj.learning.map((item, i) => {
              mylist.push({
                url: '/images/button.png',
                title: item,
                width: '30%',
              });
            });
            setData(mylist)
          }
          return () => {
            isMounted = true;
          };
        } catch (e) {
          console.log(e);
          return false;
        }
      } else if ((message.data) && (message.data.toString().includes("label"))) {
        var obj = JSON.parse(message.data);
        setDeviceList(obj);
      }
    };
  }, []);

  const imageClick = (title) => {
    setSelected(true);
    setQuizId(title);


  }

  const handler = (val) => {
    setSelected(val);
  }

  const [deviceScan, setDeviceScan] = useState(false);
  const [scanMsg, setScanMsg] = useState("Scanning devices...");
  const [selectedDevice, setSelectedDevice] = useState();
  const [deviceList, setDeviceList] = useState([]);
  const [deviceCast, setDeviceCast] = useState(false);

  const handleScan = () => {
    setDeviceScan(true);
    var cast = `{"api":"handleScan", "action":"get", "name": "${name}"}`;
    socket.send(cast)
  }

  const handleCastLearn = () => {
    setDeviceCast(true);
  }

  const handleCloseScan = () => {
    setScanMsg("Scanning devices...")
    setDeviceScan(false);
    setSelectedDevice();
    setDeviceList([]);
  }

  const handleContentChange = (e) => {
    setSelectedDevice(deviceList[e.currentTarget.value].value)
  };

  const handleSaveScan = (e) => {
    // localStorage.setItem("castDevice", selectedDevice);
    setScanMsg("Scanning devices...");
    setDeviceScan(false);
    setSelectedDevice();
    setDeviceList([]);
  };

  const handleContentChangeCast = (e) => {
    setSelectedDevice(data[e.currentTarget.value].title)
  };

  const handleCloseCast = () => {
    setDeviceCast(false);
  }

  const handleSaveCast = (e) => {
    console.log("Scanning devices...", e.currentTarget.value)
    let url = "https://app.mymatilda.com.au/learninglist/" + name + "/" + e.currentTarget.value.replaceAll(" ", "%20")
    console.log("URLTHIS ", url)
    setSelectedDevice();
    setDeviceCast(false);
    var cast = `{"api":"handleStartCastSite", "action":"start", "url": "${url}", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    socket.send(cast)
  }

  const handleStopCast = (e) => {
    var cast = `{"api":"handleStopCast", "action":"stop", "name": "${name}", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
    socket.send(cast)
  }

  const handleStopReading = () => {
    var api = `{"api":"book", "action":"stop","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })
    setIsLearningStarted(false)
  }

  const [isLearningStarted, setIsLearningStarted] = useState(false)

  if (isSelected) {
    //alert(selectquiz);
    //setSelected(false);
    return (
      <div>
        <TitleBar title="Learning List" />
        <div className={classes.root}>
          {<Learning quizid={selectquiz} handler={handler} />}
        </div>
      </div>
    );

  } else
    return (
      <div>
        <TitleBar title="Learning List" />
        <Button size="small" variant="outlined" color="primary" onClick={handleScan}>
          Scan Cast Devices
        </Button>
        <Button size="small" variant="outlined" color="primary" onClick={handleCastLearn}>
          Cast
        </Button>
        <Button size="small" variant="outlined" color="primary" onClick={handleStopCast}>
          Stop Cast
        </Button>
        {
          isLearningStarted && <Button size="small" variant="outlined" color="primary" onClick={handleStopReading}>
                  Stop Reading
                </Button>
        }
        <Dialog
          open={deviceScan}
          onClose={handleCloseScan}
          fullWidth
        >
          <DialogTitle id="form-dialog-title" onClose={handleCloseScan}>Scan Devices</DialogTitle>
          <DialogContent dividers>
            <CastSelector />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseScan} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveScan} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deviceCast}
          onClose={handleCloseCast}
          fullWidth
        >
          <DialogTitle id="form-dialog-title" onClose={handleCloseCast}>Choose Item to Cast</DialogTitle>
          <DialogContent dividers>
            <FormControl fullWidth>
              <InputLabel htmlFor="content-label">Content</InputLabel>
              <Select defaultValue={selectedDevice} value={selectedDevice} id="select-content" onChange={handleContentChangeCast} fullWidth>
                {data.map((categoria, i) => (
                  <MenuItem key={i} value={categoria.title}>{categoria.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCast} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveCast} value={selectedDevice} color="primary">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.root}>
          {data.map((image) => (
            <ButtonBase
              onClick={() => {
                console.log('Handle click');
                var api = `{"api":"learning", "action":"start","name": "${name}", "file": "${image.title}"}`;
                waitForSocketConnection(socket, () => { sendMessage(api) });
                setIsLearningStarted(true)
                // imageClick(image.title);
              }}
              focusRipple
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              style={{
                width: image.width,
              }}
            >
              <span
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <span className={classes.imageBackdrop} />
              <span className={classes.imageButton}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {image.title}
                  {/* <span className={classes.imageMarked} /> */}
                </Typography>
              </span>
            </ButtonBase>
          ))}
        </div>
      </div>
    );
}
