import { w3cwebsocket as W3CWebSocket } from "websocket";

// //let socket = new W3CWebSocket('wss://socket.mymatilda.com.au:3389');
// let socket = new W3CWebSocket('ws://35.199.153.80:3389');
//import { w3cwebsocket as W3CWebSocket } from "websocket";
var voice_map = {};
var emotion_map = {};
var speed_map = {};
var name = localStorage.getItem('remoteId');
var musicList = false;

voice_map["\\vct=140\\"] = "\\voice=Child\\";
voice_map["\\vct=130\\"] = "\\voice=Child\\";
voice_map["\\vct=120\\"] = "\\voice=Younger Adult\\";
voice_map["\\vct=110\\"] = "\\voice=Younger Adult\\";
voice_map["\\vct=100\\"] = "\\voice=Adult\\";
voice_map["\\vct=90\\"] = "\\voice=Older Adult\\";
voice_map["\\vct=80\\"] = "\\voice=Senior\\";

emotion_map["\\emotion=neutral\\"] = "\\emotion=Neutral\\";
emotion_map["\\emotion=happy\\"] = "\\emotion=Happy\\";
emotion_map["\\emotion=sad\\"] = "\\emotion=Sad\\";
emotion_map["\\emotion=disgust\\"] = "\\emotion=Disgust\\";
emotion_map["\\emtoion=anger\\"] = "\\emtoion=Anger\\";
emotion_map["\\emtoion=consfused\\"] = "\\emtoion=Consfused\\";
emotion_map["\\emtoion=fear\\"] = "\\emtoion=Fear\\";
emotion_map["\\emtoion=surprised\\"] = "\\emtoion=Surprised\\";

speed_map["\\rspd=130\\"] = "\\speed=Extreme Fast\\";
speed_map["\\rspd=120\\"] = "\\speed=Very Fast\\";
speed_map["\\rspd=110\\"] = "\\speed=Fast\\";
speed_map["\\rspd=100\\"] = "\\speed=Normal\\";
speed_map["\\rspd=90\\"] = "\\speed=Slow\\";
speed_map["\\rspd=80\\"] = "\\speed=Very Slow\\";
speed_map["\\rspd=70\\"] = "\\speed=Extreme Slow\\";

const toCode = (str) => {
    for (const [key, value] of Object.entries(voice_map)) {
        str = str.replace(value, key);
    }

    for (const [key, value] of Object.entries(speed_map)) {
        str = str.replace(value, key);
    }

    return str;
}
// const sendMessage = (msg) => {
//     // Wait until the state of the socket is not ready and send the message when it is...
//     waitForSocketConnection(socket, function(){
//         console.log("message sent!!!");
//         socket.send(msg);
//     });
// }

const toCodeV2 = (str) => {
    for (const [key, value] of Object.entries(voice_map)) {
        console.log("Voice Map ", key, value);
        str = str.replace(value, key);
    }

    for (const [key, value] of Object.entries(speed_map)) {
        str = str.replace(value, key);
        console.log("Speed Map ", key, value);
    }

    str = str.replace('"', ' ');
    str = str.replace("\\", "\\\\");
    str = str.replace("null", "")
    console.log("FINAL STRING ", str)
    return str
}

const vocalise = (text, emotion = 'neutral', sound = 'none', robotName='Matilda_229a-g') => {
    name = name || robotName;
    if (name == null) {
        let location = window.location.href.split("/")
        if (location.length == 6) {
          let robot = location[4]
          let selectTitle = location[5].replaceAll("%20", " ")
          console.log("enterjoanna ", robot, selectTitle)
          localStorage.setItem("remoteId", robot)
          name = robot
        }
    }
    var speak = { "api": "vocalise", "text": toCode(text).replaceAll("\"", "").replaceAll("'", ""), "language": "en", "emotion": emotion, "sound": sound, "name": name };
    //    console.log(JSON.stringify(speak));
    socket.send(JSON.stringify(speak));

}

const vocaliseV2 = (text, emotion = 'neutral', sound = 'none') => {
    console.log(text, emotion)
    var speak = {
        "api": "vocalise", "text": toCodeV2(text),
        "language": "en",
        "emotion": emotion,
        "sound": sound,
        "name": name
    };
    sendMessage(JSON.stringify(speak));

}

// // Make the function wait until the connection is made...
const waitForSocketConnection = (_socket, callback, retryCt=0) => {
    // if (retryCt == 5) {
    //     console.log('Joanna max retry ', retryCt);
    //     const homeUrl = window.location.origin + '/home';

    //     if (window.location.href !== homeUrl) {
    //         window.location = homeUrl;
    //     } else {
    //         console.log('Already on the home page, no redirection needed.');
    //     }
    // }
    setTimeout(
        function () {
            if (_socket.readyState === 1) {
                console.log("Connection is made")
                if (callback != null) {
                    callback();
                }
            } else if (_socket.readyState === 0) {
                console.log("wait for connection...")
                retryCt += 1;
                waitForSocketConnection(_socket, callback, retryCt);
            } else {
                socket = new W3CWebSocket('wss://socket.mymatilda.com.au:3389');
                console.log("WAIT FOR SOCKET ", socket);
                retryCt += 1;
                waitForSocketConnection(socket, callback, retryCt);
            }

        }, 100); // wait 100 milisecond for the connection...
}


// export { socket, sendMessage, vocalise }

//var WebSocketClient = require('websocket').client;

let socket = new W3CWebSocket('wss://socket.mymatilda.com.au:3389');


//let socket = () => { }

socket.onerror = function () {
    console.error('Connection Error');
};

let timerId = 0; 

socket.onopen = function () {
    console.log('WebSocket Client Connected');

    // function sendNumber() {
    //     if (socket.readyState === socket.OPEN) {
    //         var number = Math.round(Math.random() * 0xFFFFFF);
    //         socket.send(number.toString());
    //         setTimeout(sendNumber, 5000);
    //     }
    // }
    // sendNumber();
};

socket.onclose = function () {
    console.log('echo-protocol Client Closed');
    const homeUrl = window.location.origin + '/home';

    // if (window.location.href !== homeUrl && !musicList && !localStorage.getItem('remoteId')) {
    //     window.location = homeUrl;
    // } else {
    //     console.log('Already on the home page, no redirection needed.');
    // }
};

socket.onmessage = function (e) {
    if (typeof e.data === 'string') {
        if (JSON.stringify(e.data).includes("songlist")) {
            musicList = true
        }
        //socket.send(JSON.stringify(e.data));

        socket.send(JSON.stringify(e.data));
        console.log("Received2: '" + e.data + "'");
    }
};

function sendMessage(some_strin) {
    console.log("sendmaessage", some_strin)
    var parsedMsg = JSON.parse(some_strin)
    parsedMsg['remoteId'] = parsedMsg.name
    console.log(JSON.stringify(parsedMsg))
    socket.send(JSON.stringify(parsedMsg));
}



export { socket, sendMessage, vocalise, name, waitForSocketConnection, vocaliseV2, toCode, musicList }