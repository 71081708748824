import React, { useState, useRef, useEffect } from "react";
import './CallStyle.css'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Matilda1 from '../Illustrations/Matilda1';
import useWebSocket from '../../hooks/useWebSocket';
import useRemoteStream from '../../hooks/useRemoteStream';
import usePeer from '../../hooks/usePeer';
import useUserMedia from '../../hooks/useUserMedia';
import TitleBar from '../Common/TitleBar';
import RemoteStream from '../Call/RemoteStream';
import { sendMessage, socket, waitForSocketConnection, name, vocalise } from '../../socket';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CastSelector from "./CastSelector";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { func } from "prop-types";
import TranslationService from "../../services/translation.service";
import CryptoJS from 'crypto-js';

const CallReceptionist = () => {
    let history = useHistory();
    // useEffect(() => {
    //     const unlisten = history.listen(() => {
    //       console.log('User is leaving the page');
    //       alert("USER IS LEAVING")
    //       // Do something before the user leaves the page
    //     });

    //     return () => {
    //       console.log('Component is unmounting');
    //       alert("USER IS LEAVING");
    //       unlisten();
    //       // Do something before the component unmounts
    //     };
    //   }, [history]);
    let name = localStorage.getItem('remoteId');
    // if (!name) {
    //     // history.push('/home')
    // }
    useEffect(() => {
        // reloadChrome()
    }, [])
    const pageLogin = localStorage.getItem('remoteId') || window.location.href.split("/")[4];;
    const localstream = useUserMedia();
    const [remoteStream, addRemoteStream, removeRemoteStream] = useRemoteStream();
    const [peer, peerId, peerConnected] = usePeer(addRemoteStream, removeRemoteStream);
    const [isConnected, setIsConnected] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [remoteId, setRemoteId] = useState(pageLogin);
    const [headPosition, setHeadPosition] = useState({ horizontal: 0, vertical: 15 });
    const [call, setCall] = useState(null);
    const [conn, setConn] = useState(null);
    const [mute, setMute] = useState(false);
    const [muteOrNot, setmuteOrNot] = useState(false);
    const [videoOrNot, setVideoOrNot] = useState(true);

    const updateRemoteId = (e) => {
        setRemoteId(e.target.value);
    }
    //autoGainControl: false,
    //channelCount: 2,
    //echoCancellation: true,
    //latency: 0,
    //noiseSuppression: true,
    //sampleRate: 48000,
    //sampleSize: 16,
    //volume: 1.0

    // Send message via Web RTC connection
    const sendMessage2 = async (message) => {
        console.log('Sending message: ', message);
        if (conn === null) {
            return;
        }
        conn.send(JSON.stringify(message));
    }

    const openConnection = async () => {
        console.log('Joanna is here');

        // This opens the data channel
        // It's not needed for the call, but is needed for sending messages
        // (eg, controlling the robot's head movement)
        const thisConnection = peer.connect(remoteId);

        setConn(thisConnection);

        // Test a data connection
        thisConnection.on('open', () => {
            console.log('Connection Opened');

            const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let randomString = "";
            
            for (let i = 0; i < 8; i++) {
              const randomIndex = Math.floor(Math.random() * charset.length);
              randomString += charset.charAt(randomIndex);
            }

            console.log("RANDOM STRING ", randomString);
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }), randomString).toString();
            console.log("encryptedData ", encryptedData.concat("+++").concat(randomString));
            thisConnection.send(encryptedData.concat("+++").concat(randomString));
        });

        thisConnection.on('error', (error) => {
            console.log('Connection error', error)
        });

        thisConnection.on('data', (data) => {
            const parsedMessage = JSON.parse(data);
            if (parsedMessage) {
                if (parsedMessage.type === 'photo') {
                    downloadImage(parsedMessage.data, 'image.jpg');
                }
                else if (parsedMessage.includes('error')) {
                    console.log(parsedMessage)
                    console.log(parsedMessage.error)
                }
            }

        });
    }

    function toggleMute() {
        const newMuteState = !mute;
        localstream.getAudioTracks().forEach(track => track.enabled = !newMuteState);
        setMute(newMuteState);
        setmuteOrNot(newMuteState)
    }

    const handleStartCast = (e) => {
        // setIsCast(true);
        var cast = `{"api":"handleStartCastSite", "action":"start", "url": "https://gamesv2.de.r.appspot.com/", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
        // socket.send(cast)
        waitForSocketConnection(socket, () => { sendMessage(cast) });
        setIsConnecting(true);
        console.log("DONE CASTING");
        setTimeout(startSecond, 15000);
    }

    const handleStartCastAudio = (e) => {
        // setIsCast(true);
        var cast = `{"api":"handleStartCastSite", "action":"start", "url": "https://polar-equinox-385601.ue.r.appspot.com/", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
        // socket.send(cast)
        waitForSocketConnection(socket, () => { sendMessage(cast) });
        setIsConnecting(true);
        console.log("DONE CASTING");
        setTimeout(startSecondAudio, 20000);
    }

    const startSecond = () => {
        const options = {
            constraints: {
                'mandatory': {
                    'OfferToReceiveAudio': true,
                    'OfferToReceiveVideo': true
                },
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1,
            }
        }
        peer.call("Matilda_aa", localstream, options);
    }

    const startSecondAudio = () => {
        console.log("STARTING CALL");
        const options = {
            constraints: {
                'mandatory': {
                    'OfferToReceiveAudio': true,
                    'OfferToReceiveVideo': true
                },
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1,
            }
        }
        console.log("Call matilda_aa2")
        peer.call("Matilda_aa2", localstream, options);
        console.log("Call matilda 229a-g1")
        // peer.call("Matilda_229a-g", localstream, options);
    }

    const [deviceScan, setDeviceScan] = useState(false);
    const [callStart, setCallStart] = useState(false);
    const [scanMsg, setScanMsg] = useState("Scanning devices...");
    const [selectedDevice, setSelectedDevice] = useState("");
    const [deviceList, setDeviceList] = useState([]);
    const [deviceHolder, setDeviceHolder] = useState("");
    const handleScan = () => {
        // let name = localStorage.getItem('remoteId');
        var cast = `{"api":"handleScan", "action":"get", "name": "${localStorage.getItem('remoteId')}"}`;
        waitForSocketConnection(socket, () => {
            sendMessage(cast)
            setDeviceScan(true);
        })
    }

    const handleCloseScan = () => {
        setScanMsg("Scanning devices...")
        setDeviceScan(false);
        setSelectedDevice();
        setDeviceList([]);
        setCallStart(false)
    }

    const callStartFnc = () => {
        setCallStart(true)
    }

    const handleSaveScan = (e) => {
        console.log("castDevice ", selectedDevice, e.currentTarget.value, deviceHolder)
        // localStorage.setItem("castDevice", selectedDevice);
        setScanMsg("Scanning devices...");
        setDeviceScan(false);
        setSelectedDevice();
        setDeviceList([]);
    };

    const callUser = async (receiverId) => {
        // let name = localStorage.getItem('remoteId');
        waitForSocketConnection(socket, () => { vocalise("Trying to connect with " + receiverId) })
        

        var onStopSingleThread = `{"api":"onStopSingleThread", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(onStopSingleThread) })

        var api = `{"api":"echoCancel", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) })

        setIsConnecting(true);

        openConnection();

        const options = {
            constraints: {
                'mandatory': {
                    'OfferToReceiveAudio': true,
                    'OfferToReceiveVideo': true
                },
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 1,
            }
        }
        console.log("REMOTE ID ", receiverId)
        const thisCall = peer.call(receiverId, localstream, options);
        // peer.call("Caregiver_aa5", localstream, options);
        // console.log("CALLING a5")


        setCall(thisCall);

        thisCall.on('stream', (stream) => {

            addRemoteStream(stream);
            setIsConnecting(false);
            setIsConnected(true);
            // peer.call("Caregiver_aa5", localstream, options);
        });

        thisCall.on('close', () => {
            console.log("call closed");
            removeRemoteStream();
            setIsConnecting(false);
            setIsConnected(false);
            if (thisCall !== null) {
                thisCall.close();
            }
        });

        thisCall.on('error', (error) => {
            console.log("call error", error);
            removeRemoteStream();
            setIsConnecting(false);
            setIsConnected(false);
            if (thisCall !== null) {
                thisCall.close();
            }
        });
    }

    const allowVisitor = () => {
        console.log('Allow visitor')
        var cast = `{"api":"handleStartCastSite", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(cast) });
    }

    const notAllowVisitor = () => {
        console.log('Do not allow visitor')
    }

    function endCall() {
        handleStopCast();
        if (call === null) {
            return;
        }
        removeRemoteStream(call.peer);
        call.close();
        reloadChrome();
        sendMessage2('Close Call');
        setCall(null);
    }

    const handleStopCast = () => {
        var cast = `{"api":"handleStopCast", "action":"stop", "ip": "${localStorage.getItem("castDevice")}", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(cast) });
    }

    const reloadChrome = () => {
        console.log("SEND RELOAD CHROME")
        var cast = `{"api":"reloadThisChrome", "action":"reloadThisChrome", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(cast) });
    }

    const moveHead = (newHeadPosition) => {
        const message = Object.assign(newHeadPosition, { api: "head" });

        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";
        
        for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          randomString += charset.charAt(randomIndex);
        }

        console.log("RANDOM STRING ", randomString);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(message), randomString).toString();
        conn.send(encryptedData.concat("+++").concat(randomString));
        setHeadPosition(newHeadPosition);
    }

    //The below functions are for controlling the Robot Movements
    const moveHeadLeft = () => {
        if (headPosition.horizontal < 80) {
            const newHeadPosition = { horizontal: headPosition.horizontal + 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadRight = () => {
        if (headPosition.horizontal > -80) {
            const newHeadPosition = { horizontal: headPosition.horizontal - 10, vertical: headPosition.vertical };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadUp = () => {
        console.log(headPosition);
        if (headPosition.vertical < 50) {
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical + 10 };
            moveHead(newHeadPosition);
        }
    }

    const moveHeadDown = () => {
        if (headPosition.vertical > -50) {
            const newHeadPosition = { horizontal: headPosition.horizontal, vertical: headPosition.vertical - 10 };
            moveHead(newHeadPosition);
        }
    }

    const resetHead = () => {
        const newHeadPosition = { horizontal: 0, vertical: 15 };
        moveHead(newHeadPosition);
    }

    function takePhoto() {
        sendMessage2({ "api": "take_photo", "send_to": "192.168.163.30:8000/photo" });
    }

    function castToDevice() {
        handleStartCast()
        // console.log("CAST TO DEVICE");
        // let device = localStorage.getItem("castDevice")
        // if (device == null) {
        //     alert("No selected casting device!")
        // } else {
        //     handleStartCast()
        // }
    }

    function castToDeviceAudio() {
        handleStartCastAudio()
        // console.log("CAST TO DEVICE");
        // let device = localStorage.getItem("castDevice")
        // if (device == null) {
        //     alert("No selected casting device!")
        // } else {
        //     handleStartCastAudio()
        // }
    }

    function dontCast() {
        callUser(remoteId);
        setCallStart(false);
    }

    function castRobotAudio() {
        console.log("CAST TO DEVICE");
        let device = localStorage.getItem("castDevice")
        if (device == null) {
            alert("No selected casting device!")
        } else {
            callUser(remoteId);
            setTimeout(castToDevice, 5000);
            setCallStart(false);
        }
    }

    function castDeviceAudio() {
        // reloadChrome()
        console.log("CAST TO DEVICE");
        let device = localStorage.getItem("castDevice")
        if (device == null) {
            alert("No selected casting device!")
        } else {
            console.log("DEVICE AUDIOOOOOOOOOOOOOOO")
            callUser(remoteId)
            setTimeout(castToDeviceAudio, 5000);
            setCallStart(false);
        }
    }

    function callUserOnly() {
        callUser(remoteId);
    }

    function toggleVideo() {
        console.log("TOGGLE VIDEO")
        const newMuteState = !videoOrNot;
        localstream.getVideoTracks().forEach(track => track.enabled = !newMuteState);
        setVideoOrNot(newMuteState);
    }

    const downloadImage = (base64Data, fileName) => {
        // Creates a DOM element with the "download" attribute to force
        // the image to download rather than replace the location.
        const linkSource = `data:image/jpg;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage") || "English";
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    const handleTitleBarClick = (e) => {
        e.preventDefault();  // Prevent the default navigation behavior
    
        console.log("Joanna is here also");

    };

    return (

        <><div className="">
            <TitleBar title={computeTitle("Call")} parentNode={"/friends-and-relatives"} onClick={handleTitleBarClick}/>
            <Dialog
                open={deviceScan}
                onClose={handleCloseScan}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCloseScan}>Scan Devices</DialogTitle>
                <DialogContent dividers>
                    {/* {scanMsg} */}
                    <CastSelector />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseScan} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveScan} value={selectedDevice} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={callStart}
                onClose={handleCloseScan}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCloseScan}>{computeTitle("Choose Video Call Type")}</DialogTitle>
                <DialogContent dividers>
                    <Button onClick={dontCast} size="small" type="primary" variant="outlined">Don't cast</Button>
                    <br></br>
                    <Button onClick={castDeviceAudio} size="small" type="primary" variant="outlined">Cast to TV: Use TV audio</Button>
                    <br></br>
                    {/* <Button onClick={castRobotAudio} size="small" type="primary" variant="outlined">Cast to TV: Use Matilda audio</Button>
                    <br></br> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleScan} color="primary">
                        Scan Devices
                    </Button>
                    <Button onClick={handleCloseScan} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <div id="call-controls" className={isConnected ? "" : "hidden"}>
                <button className='btn btn-large' onClick={endCall}>End Call</button>
                <button className='btn btn-large' onClick={allowVisitor}>Allow</button>
                <button className='btn btn-large' onClick={notAllowVisitor}>Do not Allow</button>
                <button className='btn btn-large' onClick={toggleVideo}>Toggle Video</button>

                <button onClick={toggleMute} className={mute ? "hidden" : "btn btn-large"}>
                    <svg width="18" height="18" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 3L21 21" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 9V9C9 11.7614 11.2386 14 14 14V14M15 10.5V5C15 3.34315 13.6569 2 12 2V2C10.3431 2 9 3.34315 9 5V5.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5 10V11C5 14.866 8.13401 18 12 18V18V18C15.866 18 19 14.866 19 11V10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 18V22M12 22H9M12 22H15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Mute Yourself
                </button>

                <button onClick={toggleMute} className={!mute ? "hidden" : "btn btn-large"}>
                    <svg width="18" height="18" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 14L21 18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21 22.01L21.01 21.9989" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <rect x="7" y="2" width="6" height="12" rx="3" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M3 10V11C3 14.866 6.13401 18 10 18V18V18C13.866 18 17 14.866 17 11V10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10 18V22M10 22H7M10 22H13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Unmute Yourself
                </button>
            </div>

            <div id="call-box" className={isConnected ? "hidden" : ""}>
                <div >
                    <Matilda1 />
                </div>
                <div>
                    <div>
                        <div id="connection-status" className={isConnecting ? "" : "hidden"}>
                            {isConnecting ? 'Connecting ...' : ''}
                        </div>

                        <div className={isConnected ? "hidden" : "call-form, content-area"}>
                            <h4>{computeTitle("Please Enter ID to call")}</h4>
                            <input type="text" className="form-control form-rounded" value={remoteId} onChange={updateRemoteId} />
                            <span onClick={callUserOnly} id="connect-btn" className="btn btn-large">
                                <img src="/images/loading.svg" alt="Loading" className={isConnecting ? "loading" : "hidden"} />
                                {isConnecting ? computeTitle('Connecting') : computeTitle('Connect')}
                            </span>&nbsp;
                            <span onClick={castDeviceAudio} id="connect-btn" className="btn btn-large">
                                <img src="/images/loading.svg" alt="Loading" className={isConnecting ? "loading" : "hidden"} />
                                {isConnecting ? computeTitle('Connecting') : computeTitle('Cast to TV with TV audio')}
                            </span>&nbsp;
                            {/* <span onClick={castRobotAudio} id="connect-btn" className="btn btn-large">
                                <img src="/images/loading.svg" alt="Loading" className={isConnecting ? "loading" : "hidden"} />
                                {isConnecting ? computeTitle('Connecting') : computeTitle('Cast to TV with Matilda audio')}
                            </span>&nbsp; */}
                            <span onClick={handleScan} id="connect-btn" className="btn btn-large">
                                <img src="/images/loading.svg" alt="Loading" className={isConnecting ? "loading" : "hidden"} />
                                {computeTitle('Scan Devices')}
                            </span>&nbsp;
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className='col-12'>

                    <div className={isConnected ? "" : "hidden"} id="video-container">
                        <div id="controls-container">
                            <div id='menu'>
                                <div className="keys">
                                    <button className="centre arr" onClick={takePhoto}><i className="fa fa-camera"></i></button>
                                    <br />
                                    <button className="up arr" onClick={moveHeadUp}><i className="fa fa-arrow-up"></i></button>
                                    <br />
                                    <button className="left arr" onClick={moveHeadLeft}><i className="fa fa-arrow-left "></i></button>
                                    <button className="centre arr" onClick={resetHead}><i className="fa fa-dot-circle-o"></i></button>
                                    <button className="right arr" onClick={moveHeadRight}><i className="fa fa-arrow-right"></i></button>
                                    <br />
                                    <button className="down arr" onClick={moveHeadDown}><i className="fa fa-arrow-down"></i></button>
                                </div>
                            </div>
                        </div>
                        <div id="remote-video">
                            <RemoteStream remoteStream={remoteStream} muteOrNot={muteOrNot} />
                        </div>
                    </div>
                </div>
            </div>

        </div><link href="//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css" rel="stylesheet"></link></>
    )

}

export default CallReceptionist;