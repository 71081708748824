import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";
import { sendMessage, socket, waitForSocketConnection, name } from '../socket';

/**
 * Peer JS setup using Hooks is based on the following repo:
 * https://github.com/Cleberw3b/peerjs-client
 */

//const peerId = 'Caregiver_c80-g';
const peerId = 'Caregiver_229a-g-eyegaze'

export default function usePeerV3() {
    const peer = useRef(null);
    const [peerConnected, setPeerConnected] = useState(false);

    useEffect(() => {

        const cleanUp = () => {
            if (peer.current) {
                if (!peer.current.disconnected) { 
                    peer.current.disconnect();
                }
                try {
                if (!peer.current.destroyed) {
                    peer.current.destroy();   
                }
                }
                catch (e) { 
                    console.log(e)
                }
                // peer.current = null;
            }
        }

        peer.current = peer.current ? peer.current : new Peer(peerId,{ host:'socket.mymatilda.com.au' , port:9090 , path : '/peerpath' ,  secure:true });

        peer.current.on('open', (id) => {
            console.log('Peer opened:', id);
            setPeerConnected(true);
        })
    
        peer.current.on('call', (call) => {
            console.log('Peer CALLED:', call);
            const userMediaConfig  = {
                video: true,
                audio: {
                    autoGainControl: true,
                    channelCount: 2,
                    echoCancellation: true,
                    latency: 0,
                    noiseSuppression: true,
                    sampleRate: 48000,
                    sampleSize: 16
                }
            };
            navigator.mediaDevices.getUserMedia(userMediaConfig).then((stream) => {
                call.answer(stream);
            })
        })

        peer.current.on('connection', (data) => {
            console.log('Peer connected:', data);
        })

        peer.current.on('disconnected', () => {
            console.log("Peer disconnected");
            peer.current.reconnect();
        });

        peer.current.on('close', () => {
            console.log("Peer closed remotely");
            cleanUp()
        });

        peer.current.on('error', (error) => {
            console.log("peer error", error);
            cleanUp()
        });

        peer.current.on('data', function(caregiverData) {
            console.log('received userperv3')
        })

    }, [])

    return [peer.current, peerId, peerConnected];
}