import React, { Component, useEffect, useState } from "react";
import TitleBar from "../Common/TitleBar";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, FormControlLabel, Checkbox, Divider, Box } from "@material-ui/core";
import { useTable } from "react-table";
import styled from 'styled-components';
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";
import { useHistory } from "react-router-dom";
import axios from "axios";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}


const VoiceCommand = () => {
    const [trainMsg, setTrainMsg] = useState('');
    const [gotSongs, setGotSongs] = useState(false);
    const [songs, setSongs] = useState([]);
    const [selectedServiceIdx, setSelectedServiceIdx] = useState("");
    const [selectedService, setSelectedService] = useState("");
    const [serviceList, setServiceList] = useState([
        { value: 'main', label: 'Main' },
        { value: 'music', label: 'Music and Video' },
        { value: 'book', label: 'Book and Learning' },
        { value: 'news', label: 'News and Calendar' },
        { value: 'game', label: 'Game and Exercise' }
    ]);
    const [deviceScan, setDeviceScan] = useState(false);
    const [scanMsg, setScanMsg] = useState("Scanning devices...");
    const [selectedDevice, setSelectedDevice] = useState();
    const [deviceList, setDeviceList] = useState([]);
    const [commandOpen, setCommandOpen] = useState(false);
    const [commandText, setCommandText] = useState("");
    const [responseText, setResponseText] = useState("");
    const [isCommandView, setIsCommandView] = useState(false);
    const [commandIdx, setCommandIdx] = useState();
    const [commandView, setCommandView] = useState(false);
    const [commandEdit, setCommandEdit] = useState(false);
    const [isCommandEdit, setIsCommandEdit] = useState(false);
    const [commandDelete, setCommandDelete] = useState(false);
    const [isCommandDelete, setIsCommandDelete] = useState(false);
    const [isTrain, setIsTrain] = useState(false);
    const [isOk, setIsOk] = useState(false);
    let history = useHistory();
    const [config, setConfig] = useState(false);
    const [listenTime, setListenTime] = useState(1)
    const [monitoringTime, setMonitoringTime] = useState(10)
    const [curentLanguage, setCurrentLanguage] = useState('English')

    const handleCommandOpen = () => {
        setCommandOpen(true);
    }

    const handleSaveConfig = () => {
        let data = JSON.stringify({
            "listen_time": listenTime,
            "monitoring_time": monitoringTime,
            "robot_id": name
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/conversation-config',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setConfig(false)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const handleUpdateConfig = () => {
        var api = `{"api":"globLanGet", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://cloud-api-389807.ue.r.appspot.com/conversation-config?robot_id=${name}`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                const data = response.data.data;
                if (data.length > 0) {
                    setListenTime(data[0].listen_time || 1);
                    setMonitoringTime(data[0].monitoring_time || 10)
                }
                setConfig(true);
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleCommandTrain = () => {
        var cService = localStorage.getItem("commandService") ? localStorage.getItem("commandService") : "main"
        var service = cService.replace("\"", "").trim();
        var api = `{"api":"trainVoiceCommand", "action":"get","name": "${name}", "service": "${service}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setTrainMsg("Training...");
        setIsTrain(true);
    }
    const handleCommandView = (e) => {
        console.log("View ", songs, e.currentTarget.value);
        var cService = localStorage.getItem("commandService") ? localStorage.getItem("commandService") : "main"
        var service = cService.replace("\"", "").trim();
        var api = `{"api":"getVoiceCommandList", "action":"get","name": "${name}", "service": "${service}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setIsCommandView(true);
        setCommandIdx(e.currentTarget.value);
    }
    const handleCommandEdit = (e) => {
        var cService = localStorage.getItem("commandService") ? localStorage.getItem("commandService") : "main"
        var service = cService.replace("\"", "").trim();
        var api = `{"api":"getVoiceCommandList", "action":"get","name": "${name}", "service": "${service}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setIsCommandEdit(true);
        setCommandIdx(e.currentTarget.value);
    }
    const handleCommandDelete = (e) => {
        var cService = localStorage.getItem("commandService") ? localStorage.getItem("commandService") : "main"
        var service = cService.replace("\"", "").trim();
        var api = `{"api":"getVoiceCommandList", "action":"get","name": "${name}", "service": "${service}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setIsCommandDelete(true);
        setCommandIdx(e.currentTarget.value);
    }
    const handleCommandClose = () => {
        setCommandOpen(false);
        setCommandView(false);
        setCommandEdit(false);
        setCommandDelete(false);
        setCommandText("");
        setResponseText("");
    }
    const handleCommandSave = () => {
        console.log("Param ", commandText, responseText, selectedService);
        var api = `{"api":"createVoiceCommand", "action":"get", "name": "${name}", "command": "${commandText}", "response": "${responseText}", "service": "${selectedService}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setCommandOpen(false);
    }
    const handleScan = () => {
        setScanMsg("Scanning devices...");
        setDeviceScan(true);
        var api = `{"api":"handleScan", "action":"get", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });

        // Add a delay of 20 seconds
        setTimeout(() => {
            console.log("Joanna after delay", deviceList.length == 0)
            if (deviceList.length == 0 && !isOk) {
                console.log('Joanna ', localStorage.getItem('isOK'))
                if (localStorage.getItem('isOK') && localStorage.getItem('isOK') == 'false') {
                    setScanMsg("Scan is unsuccessful, please try again");
                }
            }
        }, 10000);
    }

    useEffect(() => {
        localStorage.setItem('isOK', 'false');
    }, []);
    const handleMenuClick = () => { }
    const handleCloseScan = () => {
        setDeviceScan(false);
    }
    const handleSaveScan = () => {
        var api = `{"api":"setCastDefault", "action":"get", "name": "${name}", "ip": "${localStorage.getItem("castDevice")}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setScanMsg("Scanning devices...");
        setDeviceScan(false);
        setSelectedDevice();
        setDeviceList([]);
        localStorage.setItem('isOK', 'false');
        // setIsOk(false)
    }
    const handleContentChange = (e) => {
        localStorage.setItem("commandService", serviceList[e.target.value].value);
        setSelectedServiceIdx(e.target.value);
        setSelectedService(serviceList[e.target.value].value);
        var api = `{"api":"getVoiceCommandList", "action":"get","name": "${name}", "service": "${serviceList[e.target.value].value}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }
    const handleContentChangeCast = (e) => {
        localStorage.setItem("castDevice", e.target.value);
    }
    const handleCommandSaveEdit = (e) => {
        console.log("Save Edit ", commandText, responseText, selectedService, commandIdx);
        var api = `{"api":"updateVoiceCommand", "action":"get", "name": "${name}", "command": "${commandText}", "response": "${responseText}", "service": "${selectedService}", "objectId": "${commandIdx}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setCommandEdit(false);
    }
    const handleCommandSaveDelete = (e) => {
        var api = `{"api":"deleteteVoiceCommand", "action":"get", "name": "${name}", "command": "${commandText}", "response": "${responseText}", "service": "${selectedService}", "objectId": "${commandIdx}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setCommandDelete(false);
    }

    const handleCommandSync = (e) => {
        var api = `{"api":"reSyncData", "action":"get", "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
        setTrainMsg('Re-sync success!');
    }

    useEffect(() => {
        if (!gotSongs) {
            var cService = localStorage.getItem("commandService") ? localStorage.getItem("commandService") : "main"
            var service = cService.replace("\"", "").trim();
            var api = `{"api":"getVoiceCommandList", "action":"get","name": "${name}", "service": "${service}"}`;
            waitForSocketConnection(socket, () => { sendMessage(api) });
        }

        socket.onmessage = (message) => {
            if ((message.data) && (message.data.toString().includes("conversations"))) {
                console.log("IS COMMAND VIEW ", isCommandView);
                let response = JSON.parse(message.data);
                let mylist = []
                response.conversations.forEach(function (path) {
                    mylist.push({ 'command': path[0], 'response': path[1] });

                })
                setSongs(mylist);
                setGotSongs(true);

                if (isCommandView) {
                    let select = response.conversations.at(commandIdx);
                    setCommandText(select[0]);
                    setResponseText(select[1]);
                    setCommandView(true);
                    setIsCommandView(false);
                }

                if (isCommandEdit) {
                    let select = response.conversations.at(commandIdx);
                    setCommandText(select[0]);
                    setResponseText(select[1]);
                    setCommandEdit(true);
                    setIsCommandEdit(false);
                }

                if (isCommandDelete) {
                    let select = response.conversations.at(commandIdx);
                    setCommandText(select[0]);
                    setResponseText(select[1]);
                    setCommandDelete(true);
                    setIsCommandDelete(false);
                }
            } else if ((message.data) && (message.data.toString().includes("label"))) {
                setIsOk(true)
                localStorage.setItem('isOK', 'true');
                var obj = JSON.parse(message.data);
                setDeviceList(obj);
                setScanMsg("Done scanning devices");
            }
            else if ((message.data) && (message.data.toString().includes("ok"))) {
                if (isTrain) {
                    setTrainMsg("Done Training!");
                    setIsTrain(false);
                }
            } else if (message.data && message.data.toString().trim().split(' ').length === 1) {
                setCurrentLanguage(message.data.replaceAll('"', ""));
            }
            console.log('joanna ', message.data)
        }

    }, [songs, gotSongs]);

    // Memos
    const columns = React.useMemo(
        () => [
            {
                Header: 'Command',
                accessor: 'command',

            },
            {
                Header: 'Response',
                accessor: 'response',

            },
            {
                Header: 'Action',
                accessor: 'url',
                Cell: ({ cell }) => (
                    <div>
                        <Button size="large" type="primary" variant="outlined" value={cell.row.index} onClick={handleCommandView}>View</Button>
                        <Button size="large" type="primary" variant="outlined" value={cell.row.index} onClick={handleCommandEdit}>Edit</Button>
                        <Button size="large" type="primary" variant="outlined" value={cell.row.index} onClick={handleCommandDelete}>Delete</Button>
                    </div>
                )

            },


        ],
        []
    )

    const handleStartRecognition = () => {
        var api = `{"action":"handleStartRecognition", "name":"${name}" }`;
        waitForSocketConnection(socket, () =>  sendMessage(api) )
    }

    const handleStopRecognition = () => {
        var api = `{"action":"handleStopRecognition", "name":"${name}" }`;
        waitForSocketConnection(socket, () =>  sendMessage(api) )
    }

    return (
        <Styles>
            <TitleBar title={"Voice Command"} />
            <Button size="large" variant="outlined" color="primary" value="add" onClick={handleScan}>Set Default Cast Device</Button>
            <Button size="large" variant="outlined" color="primary" value="add" onClick={handleCommandOpen}>Add New Command</Button>
            <Button size="large" variant="outlined" color="primary" value="add" onClick={handleCommandTrain}>Train</Button>
            <Button size="large" variant="outlined" color="primary" value="add" onClick={handleCommandSync}>Re-sync datda</Button>
            <Button size="large" variant="outlined" color="primary" value="add" onClick={() => history.push("/command-control")}>Command Control</Button>
            <Button size="large" variant="outlined" color="primary" value="add" onClick={handleUpdateConfig}>Configuration</Button>
            <i>{trainMsg}</i>
            <br />
            <FormControl fullWidth>
                <InputLabel htmlFor="content-label">Content</InputLabel>
                <Select value={selectedServiceIdx} id="select-content" onChange={handleContentChange}>
                    {serviceList.map((categoria, i) => (
                        <MenuItem key={i} value={i} onClick={handleMenuClick}>{categoria.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Table columns={columns} data={songs} />
            <Dialog
                open={deviceScan}
                onClose={handleCloseScan}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCloseScan}>Scan Devices</DialogTitle>
                <DialogContent dividers>
                    {scanMsg}
                    <FormControl fullWidth>
                        <InputLabel htmlFor="content-label">Content</InputLabel>
                        <Select defaultValue={selectedDevice} value={selectedDevice} id="select-content" onChange={handleContentChangeCast}>
                            {deviceList.map((categoria, i) => (
                                <MenuItem key={i} value={categoria.value}>{categoria.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseScan} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveScan} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={config}
                onClose={() => setConfig(false)}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={() => setConfig(false)}>Configuration</DialogTitle>
                <DialogContent dividers>
                    <FormControl fullWidth margin="dense">
                        <Divider />
                        Conversation
                        <Divider />
                        <TextField
                            label="Listening Time (seconds)"
                            value={listenTime}
                            onChange={(e) => setListenTime(e.target.value)}
                            fullWidth
                            margin="dense"
                            type="text"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <TextField
                            label="Monitoring timeout (seconds)"
                            value={monitoringTime}
                            onChange={(e) => setMonitoringTime(e.target.value)}
                            fullWidth
                            margin="dense"
                            type="text"
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        />
                        <TextField
                            label="Current language"
                            value={curentLanguage}
                            onChange={(e) => setCurrentLanguage(e.target.value)}
                            fullWidth
                            margin="dense"
                            type="text"
                        />
                        <Divider />
                        Face Recognition
                        <Divider />
                        <Box display="flex" justifyContent="flex-start" gap={1} mt={2}>
                            <Button variant="contained" color="primary" size="small" onClick={handleStartRecognition}>Start Recognition</Button>
                            <Button variant="contained" color="secondary" size="small" onClick={handleStopRecognition}>Stop Recognition</Button>
                        </Box>

                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfig(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveConfig} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={commandOpen}
                onClose={handleCommandClose}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCommandClose}>Create New Voice Command</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Command"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setCommandText(e.target.value);
                        }}
                        value={commandText}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Response"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setResponseText(e.target.value);
                        }}
                        value={responseText}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommandClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCommandSave} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={commandView}
                onClose={handleCommandClose}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCommandClose}>View Command Details</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Command"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setCommandText(e.target.value);
                        }}
                        value={commandText}
                        disabled
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Response"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setResponseText(e.target.value);
                        }}
                        value={responseText}
                        disabled
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommandClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={commandEdit}
                onClose={handleCommandClose}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCommandClose}>Edit Command Details</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        label="Command"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setCommandText(e.target.value);
                        }}
                        value={commandText}
                        fullWidth
                    />
                    <br />
                    <TextField
                        label="Response"
                        inputProps={{
                            maxLength: 200,
                        }}
                        onChange={e => {
                            setResponseText(e.target.value);
                        }}
                        value={responseText}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommandClose} color="primary">
                        Close
                    </Button>
                    <Button onClick={handleCommandSaveEdit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={commandDelete}
                onClose={handleCommandClose}
                fullWidth
            >
                <DialogTitle id="form-dialog-title" onClose={handleCommandClose}>Delete Confirmation</DialogTitle>
                <DialogContent dividers>
                    Ooooops! You are about to delete the following voice command details.
                    <br /><br />
                    Command: <b>{commandText}</b><br />
                    Response: <b>{responseText}</b>
                    <br /><br />
                    Above voice command details will be removed permanently.
                    <br /><br />
                    Are you sure you want to continue?
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCommandClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCommandSaveDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Styles>
    )
};

export default VoiceCommand;