import React, { useEffect } from 'react'
import useStream from '../../hooks/useStream';
import Call  from '../analytics/Call';
import "./remoteStream.css"

export default function RemoteStream({ remoteStream,muteOrNot }) {

  const [setStream, remoteVideoRef, handleCanPlayRemote] = useStream();

  useEffect(() => {
    if (remoteStream)
      setStream(remoteStream);
  }, [setStream, remoteStream])

  return (
    <div className="remote">
        {/* <video onContextMenu={event => event.preventDefault()} ref={remoteVideoRef} onCanPlay={handleCanPlayRemote} autoPlay playsInline muted={muteOrNot}  /> */}
        <video
        onContextMenu={(event) => event.preventDefault()}
        ref={remoteVideoRef}
        onCanPlay={handleCanPlayRemote}
        autoPlay
        playsInline
        muted={muteOrNot}
        style={{
          width: '100%',  // Makes the video take the full width of the container
          height: '400px', // Makes the video take the full height of the container
          objectFit: 'cover' // Ensures the video covers the entire area while maintaining aspect ratio
        }}
      />
        <div class="rectangle-overlay"></div>
    </div>
  )
}