import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";
import { sendMessage, socket, waitForSocketConnection, name, vocalise } from '../socket';

/**
 * Peer JS setup using Hooks is based on the following repo:
 * https://github.com/Cleberw3b/peerjs-client
 */

//const peerId = 'Caregiver_c80-g';
const peerId = 'Caregiver_229a-g1'

export default function usePeer(addRemoteStream, removeRemoteStream) {
    const peer = useRef(null);
    const [peerConnected, setPeerConnected] = useState(false);

    useEffect(() => {

        const cleanUp = () => {
            if (peer.current) {
                if (!peer.current.disconnected) {
                    peer.current.disconnect();
                }
                try {
                    if (!peer.current.destroyed) {
                        peer.current.destroy();
                    }
                }
                catch (e) {
                    console.log(e)
                }
                // peer.current = null;
            }
        }

        peer.current = peer.current ? peer.current : new Peer(peerId, { host: 'socket.mymatilda.com.au', port: 9090, path: '/peerpath', secure: true });

        peer.current.on('open', (id) => {
            console.log('Peer opened:', id);
            setPeerConnected(true);
        })

        peer.current.on('call', (call) => {
            console.log('Peer CALLED:', call);
            const userMediaConfig = {
                video: true,
                audio: {
                    autoGainControl: true,
                    channelCount: 2,
                    echoCancellation: true,
                    latency: 0,
                    noiseSuppression: true,
                    sampleRate: 48000,
                    sampleSize: 16
                }
            };
            navigator.mediaDevices.getUserMedia(userMediaConfig).then((stream) => {
                call.answer(stream);
            })
        })

        peer.current.on('connection', (data) => {
            console.log('Peer connected:', data);
        })

        peer.current.on('disconnected', () => {
            console.log("Peer disconnected");
            peer.current.reconnect();
        });

        peer.current.on('close', () => {
            console.log("Peer closed remotely");
            cleanUp()
        });

        peer.current.on('error', (error) => {
            console.log("peer error", error);
            // waitForSocketConnection(socket, () => { vocalise("You might have ongoing call. The call has been cancelled.") });
            setTimeout(() => {
                window.location = window.location.origin + "/friends-and-relatives";
                alert("You might have ongoing call. The call has been cancelled.")
            }, 1500);
            cleanUp()
        });

        // PD, this seems only necessary if other people need to call the webapp.
        // peer.current.on('call', (call) => {
        //     console.log('receiving call from ' + call.peer)

        //     navigator.mediaDevices.getUserMedia(userMediaConfig)
        //     .then((stream) => {
        //         // Answer the call with an A/V stream.
        //         call.answer(stream);

        //         // Play the remote stream
        //         call.on('stream', (remoteStream) => {
        //             console.log('incoming call stream');
        //             addRemoteStream(remoteStream, call.peer);
        //         });

        //         call.on('close', () => {
        //             console.log("The call has ended");
        //             removeRemoteStream(call.peer);
        //         });

        //         call.on('error', (error) => {
        //             console.log(error);
        //             removeRemoteStream(call.peer);
        //         });
        //     }).catch(error => { console.log(error); });
        // });

    }, [addRemoteStream, removeRemoteStream])

    return [peer.current, peerId, peerConnected];
}