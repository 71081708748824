import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import { ButtonBase, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, Typography, Select, MenuItem } from "@material-ui/core";
import TranslationService from "../../services/translation.service";
import axios from "axios";
import { Middleware } from "./Middleware";
import { socket, waitForSocketConnection } from "../../socket";


const styles = (theme) => ({});

class NewBingo extends Component {
    constructor() {
        super();
        this.state = {
            appMeta: [
                {
                    svg: `
                        <path d="M21 3V21H3V3H21Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 16.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 7.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #1b6c72, #2498a1)',
                    title: 'Number Bingo',
                    slug: '/number-card-1to20',
                    locations: ['home'],
                },
                {
                    svg: `
                        <path d="M21 3V21H3V3H21Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 16.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 7.5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.5 3V21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                    `,
                    background: 'linear-gradient(0deg, #550678, #7709a8)',
                    title: 'Bingo Card',
                    slug: '/bingo-card',
                    locations: ['home'],
                },
            ],
            selected: false,
            quizId: null,
            showHow: false,
            deviceCast: false,
            allowedAcccess: [],
            selectedDevice: ''
        };
        this.appClick = this.appClick.bind(this);
        this.computeTitle = this.computeTitle.bind(this);
        this.handleCastLearn = this.handleCastLearn.bind(this);
        this.handleCloseCast = this.handleCloseCast.bind(this);
        this.handleContentChangeCast = this.handleContentChangeCast.bind(this);
    }

    componentDidMount() {
        const userEmail = localStorage.getItem("userEmail");
        const remoteId = localStorage.getItem("remoteId");
        console.log("Mounting emotional well being ", userEmail, remoteId);

        Middleware();

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://cloud-api-389807.ue.r.appspot.com/service-access?robot=' + remoteId + '&username=' + userEmail,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Mounting data", response.data.data.access);
                this.setState({ allowedAcccess: response.data.data.access });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    appClick = (title, slug) => {
        this.setState({ selected: true, quizId: title })
        window.location = window.location.origin + slug;
    }

    computeTitle = (title) => {
        console.log("COMPUTE TITLE")
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
            console.log("TITLE ", title)
        }
        return title
    }

    computeScreenSize = () => {
        if (window.innerWidth > 700) {
            return '1fr 1fr 1fr';
        }
        return '1fr';
    }

    handleCastLearn = () => {
        this.setState({ deviceCast: true })
    }

    handleCloseCast = () => {
        this.setState({ deviceCast: false })
    }

    handleSaveCast = (e) => {
        console.log('Joanna ', e.currentTarget.value)
        let url = e.currentTarget.value;
        this.setState({ deviceCast: false, selectedDevice: '' })
        var cast = `{"api":"handleStartCastSite", "action":"start", "url": "${url}", "ip": "${localStorage.getItem("castDevice")}", "name": "${localStorage.getItem("remoteId")}"}`;
        waitForSocketConnection(socket, () => { socket.send(cast) });
    }


    handleContentChangeCast = (e) => {
        console.log('Joanna ', e)
        // setSelectedDevice(this.state.data[e.currentTarget.value].title)
        this.setState({ selectedDevice: e.target.value })
    };

    render() {
        return (
            <div id="main-menu-panel">
                <TitleBar title={this.computeTitle("Bingo")} />
                <Button size="large" variant="outlined" onClick={() => this.setState({ showHow: true })}>How to play?</Button>
                <Button size="large" variant="outlined" onClick={this.handleCastLearn}>Cast</Button>
                <Dialog
                    open={this.state.deviceCast}
                    onClose={this.handleCloseCast}
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title" onClose={this.handleCloseCast}>Choose Item to Cast</DialogTitle>
                    <DialogContent dividers>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="content-label">Content</InputLabel>
                            <Select
                                defaultValue={this.state.selectedDevice}
                                value={this.state.selectedDevice}
                                id="select-content"
                                onChange={this.handleContentChangeCast}
                                fullWidth
                            >
                                {[{ title: 'Number Bingo', url: `https://bingo-417307.ue.r.appspot.com/number-card-1to20?remoteId=${localStorage.getItem("remoteId")}` },
                                { title: 'Card Bingo', url: `https://bingo-417307.ue.r.appspot.com/bingo-card?remoteId=${localStorage.getItem("remoteId")}` }
                                ].map((categoria, i) => (
                                    <MenuItem key={i} value={categoria.url}>
                                        {categoria.title}
                                    </MenuItem>
                                ))}
                            </Select>

                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseCast} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSaveCast} value={this.state.selectedDevice} color="primary">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showHow}
                    onClose={() => this.setState({ showHow: false })}
                    fullWidth
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>How To Play BINGO?</DialogTitle>
                    <DialogContent>
                        <div>
                            <p>
                                Hey there, future Bingo champ! 🎉 Get ready to dive into the world of Bingo, a super fun and exciting game. Here's a simple step-by-step guide to get you started:
                            </p>
                            <p>
                                <strong>Step 1: Gather Your Materials</strong><br />
                                Grab your Bingo cards and some markers. You can use coins, buttons, or even colorful candies - just something to cover the numbers on your card when they're called out.
                            </p>
                            <p>
                                <strong>Step 2: Get Your Bingo Cards </strong><br />
                                Each player gets a Bingo card with a grid of numbers. These numbers are all different and are randomly placed on the card. Every card is a winner in its own unique way!
                            </p>
                            <p>
                                <strong>Step 3: Matilda’s Role</strong><br />
                                Get Matilda to be the caller. Its job is to pick a number at random and announce it to everyone.
                            </p>
                            <p>
                                <strong>Step 4: Mark Your Card</strong><br />
                                Listen carefully to Matilda. If it announces a number that matches one on your card, cover it up with your marker. Remember, you're trying to get a line of covered numbers in a row, column, or diagonal.
                            </p>
                            <p>
                                <strong>Step 5: Shout BINGO! </strong><br />
                                Keep covering numbers on your card as Matilda announces them. Once you've covered a full row, column, or diagonal, you've got BINGO! Be quick to shout "BINGO!" so everyone knows you've won.
                            </p>
                            <p>
                                <strong>Step 6: Check Your Card</strong><br />
                                When you shout BINGO, Matilda will stop. Check your card to make sure you really got a winning line. If you did, you're the winner of that round!
                            </p>
                            <p>
                                <strong>Step 7: Play Again</strong><br />
                                Bingo is so much fun that you'll want to play again and again. Clear your card, gather your markers, and get ready for another round of excitement!
                            </p>
                            <p>
                                Remember, Bingo is all about having a blast and enjoying the thrill of the game. So go on, grab your friends and family, and let the Bingo adventure begin! 🎉🎊🎈
                            </p>
                        </div>
                    </DialogContent>
                    <Divider></Divider>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showHow: false })}>Close</Button>
                    </DialogActions>
                </Dialog>
                <div style={{
                    display: 'grid',
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: this.computeScreenSize(),
                }} id="blocks">
                    {this.state.appMeta.map((app) => (
                        <ButtonBase
                            onClick={() => this.appClick(app.title, app.slug)}
                            focusRipple
                            key={this.computeTitle(app.title)}
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '1rem',
                                color: '#fff',
                                justifyContent: 'flex-start',
                            }}
                            focusVisibleClassName={styles.focusVisible}
                        >
                            {
                                app.img ?
                                    <span style={{
                                        transition: '0.5s ease',
                                        display: 'flex',
                                        borderRadius: '50%',
                                        height: '100px',
                                        width: '100px',
                                        backgroundSize: 'cover',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <svg style={{
                                            width: '65px',
                                            height: '65px',
                                            color: '#fff',
                                        }}
                                            width="24"
                                            height="24"
                                            strokeWidth="1.5"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            dangerouslySetInnerHTML={{ __html: app.svg }} />
                                    </span>
                                    :
                                    <span style={{
                                        background: app.background,
                                        transition: '0.5s ease',
                                        display: 'flex',
                                        borderRadius: '50%',
                                        height: '100px',
                                        width: '100px',
                                        backgroundSize: 'cover',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <svg style={{
                                            width: '65px',
                                            height: '65px',
                                            color: '#fff',
                                        }}
                                            width="24"
                                            height="24"
                                            strokeWidth="1.5"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            dangerouslySetInnerHTML={{ __html: app.svg }} />
                                    </span>
                            }
                            <span style={{
                                color: '#333',
                                fontWeight: 'bold',
                                fontSize: '1.1rem'
                            }}>
                                <Typography
                                    component="span"
                                    variant="subtitle1"
                                    color="inherit"
                                    style={{
                                        color: '#333',
                                        fontWeight: 'bold',
                                        fontSize: '1.1rem'
                                    }}
                                >
                                    {this.computeTitle(app.title)}
                                </Typography>
                            </span>
                        </ButtonBase>
                    ))}
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(NewBingo);