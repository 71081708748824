import React from "react";
import TitleBar from "../Common/TitleBar";
import Button from '@material-ui/core/Button';
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { CardContent, Grid, Card, CardHeader, CardActionArea } from "@material-ui/core";
import { name, sendMessage, socket, waitForSocketConnection } from "../../socket";
import usePeerV3 from "../../hooks/usePeerV3";

const EyeGaze = () => {
    const [peer, peerId, peerConnected] = usePeerV3();
    const [img64, setImg64] = useState(false);

    const openConnection = async () => {

        const thisConnection = peer.connect('Matilda_229a-g-eyegaze');

        // setConn(thisConnection);

        // Test a data connection
        thisConnection.on('open', () => {
            console.log('Connection Opened');
            thisConnection.send(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }));

            thisConnection.on('data', function(data) {
                console.log('Received ON OPEN', data);
              })
        });

        thisConnection.on('error', (error) => {
            console.log('Connection error', error)
        });

        thisConnection.on('close', (error) => {
            console.log('connection closed')
        });

        thisConnection.on('data', (data) => {
            console.log("HOLAAAAAAA")
            const parsedMessage = JSON.parse(data);
            console.log("received ", parsedMessage);
            if (data.includes("eyegazephoto")) {
                console.log('eyegazephoto data', parsedMessage.eyegazephoto);
                setImg64(parsedMessage.eyegazephoto);
                thisConnection.send(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }));
            }
            // thisConnection.send(JSON.stringify({ api: "head", horizontal: 0, vertical: 15 }));
            // if (parsedMessage) {
            //     if (parsedMessage.type === 'photo') {
            //         // downloadImage(parsedMessage.data, 'image.jpg');
            //         console.log("Download image")
            //     }
            //     else if (parsedMessage.includes('error')) {
            //         console.log(parsedMessage)
            //         console.log(parsedMessage.error)
            //     }
            // }

        });
    }

    const onStart = () => {
        var api = `{"api":"eyegazestart", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    const onStop = () => {
        var api = `{"api":"eyegazestop", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) });
    }

    return (
        <>
            <TitleBar title="Eye Gaze" parentNode={"/technology-tools"}/>
            <button onClick={onStart}>Start</button>
            <button onClick={onStop}>Stop</button>
            {
                !img64 ? <button onClick={openConnection}>Click me!</button> : <img src={`data:image/png;base64,${img64}`}/>
            }
            
            
        </>
    );
};

export default EyeGaze;