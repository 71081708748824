import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import Login from "./components/Login";
import Register from "./components/Register";
// import AdminRegister from "./components/AdminRegister";
// import Home from "./components/Home";
import Profile from "./components/Profile";

import Video from "./components/Video/Video";
// import YouTube from "./components/Video/YouTube";
import Player from "./components/Video/Player";
// import LogTable from "./components/analytics/LogTalble";
import RobotStatus from "./components/analytics/RobotStatus";
import Music from "./components/analytics/Music";
import YouTube from "./components/analytics/YouTube";
import YouTubeWithVideo from "./components/analytics/YouTubeWithVideo";
import HealthAndWellBeing from "./components/analytics/HealthAndWellBeing";
import ExerciseList from "./components/Exercise/exerciselist";
import Book from "./components/analytics/Book";


import ImageQuiz from "./components/quiz/ImageQuiz";
import QuizList from "./components/quiz/quizlist";
import LearningList from "./components/quiz/learninglist";
import BingoList from "./components/quiz/bingolist";
import ChangePassword from "./components/ChangePassword";

import MyCalendar from "./components/calendar/MyCalendar";

import Analytics from "./components/analytics/Analytics";
import WifiSetting from "./components/analytics/WifiSetting";
import NewsApp from "./components/analytics/NewsApp";
// import GameList from "./components/games/gamelist";
import Games from "./components/analytics/Games";
import MemoryGame from "./components/analytics/MemoryGame";
import TicTacToe from "./components/analytics/TicTacToe";
import Maths from "./components/analytics/Maths";
import Chess from "./components/analytics/Chess";
import Sudoku from "./components/analytics/Sudoku";
import Scrabble from "./components/analytics/Scrabble";
import Call from "./components/analytics/Call";
import CallV2 from "./components/analytics/CallV2";
import RobotBlocks from "./components/analytics/RobotBlocks";
import WorkflowDesinger from "./components/analytics/WorkflowDesigner";
import Messenger from './components/Common/Messenger'
import MessengerAudio from './components/Common/MessengerAudio'
import AppsMeta from './components/Common/AppsMeta';
import TitleBar from './components/Common/TitleBar';
import Settings from "./components/Settings/settings";
import { Fab } from "@material-ui/core";
import Family from "./components/Family/Family";
import SmartHome from "./components/analytics/SmartHome";
import VoiceCom from "./components/analytics/VoiceCom";
import TechnologySupport from "./components/analytics/TechnologySupport";
import Hobbies from "./components/analytics/Hobbies";
import Entertainment from "./components/analytics/Entertainment";
import AudioCall from "./components/analytics/AudioCall";
import EmotionalWellBeing from "./components/analytics/EmotionalWellBeing";
import BrainTraining from "./components/analytics/BrainTraining";
import FriendsAndRelatives from "./components/analytics/FriendsAndRelatives";
import ServiceSchedulingAndManagement from "./components/analytics/ServiceSchedulingAndManagement";
import Safety from "./components/analytics/Safety";
import TechnologyTools from "./components/analytics/TechnologyTools";
import TalkWithMatilda from "./components/analytics/TalkWithMatilda";
import NewGames from "./components/analytics/NewGames";
import UserManage from "./components/analytics/UserManage";
import RobotManage from "./components/analytics/RobotManage";
import LogTable from "./components/analytics/LogTalble";
import ServicePattern from "./components/analytics/ServicePattern";
import AdminPage from "./components/analytics/AdminPage";
import TranslationService from "./services/translation.service";
import Fitbit from "./components/analytics/Fitbit";
import Monitoring from "./components/analytics/Monitoring";
import ServicePreference from "./components/analytics/ServicePreference";
import Translation from "./components/analytics/Translation";
import FaceDetection from "./components/analytics/FaceDetection";
import SystemSettings from "./components/analytics/SystemSettings";
import EmotionRecognition from "./components/analytics/EmotionRecognition";
import VoiceCommand from "./components/analytics/VoiceCommand";
import HeartRate from "./components/analytics/HeartRateV2";
import ManageProfile from "./components/analytics/Profile";
import BingoCard from "./components/analytics/BingoCard";
import NumberCard1to20 from "./components/analytics/NumberCard1to20";
import ImageQuizOrig from "./components/quiz/ImageQuizOrig";
import BingoCardSergius from "./components/analytics/BingoCardSergius";
import ConversationQuestion from "./components/analytics/ConversationQuestion";
import CommandControl from "./components/analytics/CommandControl";
import CallReceptionist from "./components/analytics/CallReceptionist";
import MonitoringV2 from "./components/analytics/MonitoringV2";
import ReceptionistCrud from "./components/analytics/ReceptionistCrud";
import Conversation from "./components/analytics/Conversation";
import Reception from "./components/analytics/Reception";
import ConversationLog from "./components/analytics/ConversationLog";
import School from "./components/analytics/School";
import CookingList from "./components/Cooking/cookinglist";
import FaceRecognition from "./components/analytics/FaceRecognition";
import TrainingModule from "./components/analytics/TrainingModule";
import { Middleware } from "./components/analytics/Middleware";
import NewBingo from "./components/analytics/NewBingo";

const homeApps = AppsMeta.filter((app) => {
  return app.locations.indexOf('home') > -1;
})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gap: '2rem',
    width: '100%',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  fab: {
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',
    zIndex: 9,
    fontSize: '11px'

  },
  image: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    color: '#fff',
    justifyContent: 'flex-start',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageSrc': {
        transform: 'scale(1.2)',
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '0',
      },
    },
  },
  '&$focusVisible': {
    background: '#000',
    opacity: 1,
  },
  imageButton: {
  },
  imageSrc: {
    transition: '0.5s ease',
    display: 'flex',
    borderRadius: '50%',
    height: '100px',
    width: '100px',
    background: '#000',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '120px',
      width: '120px',
    }
  },
  imageSVG: {
    width: '65px',
    height: '65px',
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      height: '60px',
      width: '60px',
    }
  },
  imageTitle: {
    color: '#333',
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  imageMarked: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity'),
  },
}));

export default function Menu() {
  let history = useHistory();
  const [selectquiz, setQuizId] = useState('');
  const [isSelected, setSelected] = useState(false);
  const classes = useStyles();

  const appClick = (title, slug) => {
    setSelected(true);
    setQuizId(title); // PD, What is this doing here?
    history.push(slug);
  }

  const leavingCall = () => {
    console.log("LEAVING CALL")
  }

  const handler = (val) => {
    setSelected(val);
  }
  useEffect(() => {

    const remid = localStorage.getItem('remoteId');
    console.log("Current Language", localStorage.getItem("pageLanguage"), remid);
    // if (!remid) {
    //   history.push('/home')
    // }

    Middleware()
  }
    , []);


  // if (isSelected)
  // {
  //   if (selectquiz === 'Memory Game')
  //   {
  //     return (<div className={classes.root}>{<MemoryGame />}</div>);
  //   }
  //   if (selectquiz === 'Tic Tac Toe')
  //   {
  //     return (<div className={classes.root}>{<TicTacToe />}</div>);
  //   }
  //   if (selectquiz === 'Maths')
  //   {
  //     return (<div className={classes.root}>{<Maths />}</div>);
  //   }
  //   if (selectquiz === 'Chess')
  //   {
  //     return (<div className={classes.root}>{<Chess />}</div>);
  //   }

  //   //alert(selectquiz);
  //   //setSelected(false);


  // }else

  const translateData = () => {
    let data = {
      "My Way.mp3": "{\"My Way.mp3\":{\"Mandarin\":\"我的路.mp3\",\"Japanese\":\"私のやり方.mp3\"}}",
      "Example - Sam": "{\"Example - Sam\":{\"Mandarin\":\"示例 - 山姆\",\"Japanese\":\"例 - サム\"}}",
      "Start": "{\"Start\":{\"Mandarin\":\"开始\",\"Japanese\":\"始める\"}}",
      "Audio Call listing": "{\"Audio Call listing\":{\"Mandarin\":\"音频通话列表\",\"Japanese\":\"音声通話リスト\"}}",
      "Justine Clarke - Imagination.mp3": "{\"Justine Clarke - Imagination.mp3\":{\"Mandarin\":\"贾斯汀·克拉克 - Imagination.mp3\",\"Japanese\":\"ジャスティン・クラーク - Imagination.mp3\"}}",
      "Patty's First Day of School": "{\"Patty's First Day of School\":{\"Mandarin\":\"帕蒂上学的第一天\",\"Japanese\":\"パティの学校初日\"}}",
      "Maya": "{\"Maya\":{\"Mandarin\":\"玛雅人\",\"Japanese\":\"マヤ\"}}",
      "Track 2": "{\"Track 2\":{\"Mandarin\":\"轨道 2\",\"Japanese\":\"トラック2\"}}",
      "BOOKPLAY": "{\"BOOKPLAY\":{\"Mandarin\":\"书本\",\"Japanese\":\"ブックプレイ\"}}",
      "The Hold My Hand Rap - Slower Version": "{\"The Hold My Hand Rap - Slower Version\":{\"Mandarin\":\"握住我的手说唱 - 慢速版本\",\"Japanese\":\"ホールド・マイ・ハンド・ラップ - スローバージョン\"}}",
      "General Quiz - Copy": "{\"General Quiz - Copy\":{\"Mandarin\":\"一般测验 - 副本\",\"Japanese\":\"一般クイズ - コピー\"}}",
      "Japanese": "{\"Japanese\":{\"Mandarin\":\"日本人\",\"Japanese\":\"日本\"}}",
      "Australian Phonics Song.mp3": "{\"Australian Phonics Song.mp3\":{\"Mandarin\":\"澳大利亚拼音歌曲.mp3\",\"Japanese\":\"オーストラリアンフォニックスソング.mp3\"}}",
      "Videos & Movies": "{\"Videos & Movies\":{\"Mandarin\":\"影片\",\"Japanese\":\"動画\"}}",
      "Freeze Dance Freeze Song Freeze Dance for Kids Music for Kids The Kiboomers.mp3": "{\"Freeze Dance Freeze Song Freeze Dance for Kids Music for Kids The Kiboomers.mp3\":{\"Mandarin\":\"Freeze Dance Freeze Song Freeze Dance for Kids 儿童音乐 The Kiboomers.mp3\",\"Japanese\":\"フリーズ ダンス フリーズ ソング 子供のためのフリーズ ダンス 子供のための音楽 The Kiboomers.mp3\"}}",
      "balang ganha bungangbu burrami.mp3": "{\"balang ganha bungangbu burrami.mp3\":{\"Mandarin\":\"Balang ganha bungangbu burrami.mp3\",\"Japanese\":\"バランガンハブンガンブブラミ.mp3\"}}",
      "Music": "{\"Music\":{\"Mandarin\":\"音乐\",\"Japanese\":\"音楽\"}}",
      "Connect": "{\"Connect\":{\"Mandarin\":\"连接\",\"Japanese\":\"接続\"}}",
      "switch.smart_plug_1": "{\"switch.smart_plug_1\":{\"Mandarin\":\"switch.smart_plug_1\",\"Japanese\":\"switch.smart_plug_1\"}}",
      "Calendar based Service Scheduling": "{\"Calendar based Service Scheduling\":{\"Mandarin\":\"基于日历的服务调度\",\"Japanese\":\"カレンダーベースのサービススケジュール設定\"}}",
      "Sudoku": "{\"Sudoku\":{\"Mandarin\":\"数独\",\"Japanese\":\"数独\"}}",
      "小星": "{\"小星\":{\"Mandarin\":\"小星\",\"Japanese\":\"小さな星\"}}",
      "Frank": "{\"Frank\":{\"Mandarin\":\"坦率\",\"Japanese\":\"フランク\"}}",
      "Dazzle Duckling": "{\"Dazzle Duckling\":{\"Mandarin\":\"炫小鸭\",\"Japanese\":\"ダズルアヒルの子\"}}",
      "Select Language": "{\"Select Language\":{\"Mandarin\":\"选择语言\",\"Japanese\":\"言語を選択する\"}}",
      "STARTMOTION": "{\"STARTMOTION\":{\"Mandarin\":\"启动运动\",\"Japanese\":\"スタートモーション\"}}",
      "Safety": "{\"Safety\":{\"Mandarin\":\"安全\",\"Japanese\":\"安全性\"}}",
      "Choose Video Call Type": "{\"Choose Video Call Type\":{\"Mandarin\":\"选择视频通话类型\",\"Japanese\":\"ビデオ通話の種類を選択してください\"}}",
      "CommunicationMode": "{\"CommunicationMode\":{\"Mandarin\":\"通讯方式\",\"Japanese\":\"通信モード\"}}",
      "Shapes": "{\"Shapes\":{\"Mandarin\":\"形状\",\"Japanese\":\"形状\"}}",
      "Scrabble": "{\"Scrabble\":{\"Mandarin\":\"拼字游戏\",\"Japanese\":\"スクラブル\"}}",
      "MUSICLIST": "{\"MUSICLIST\":{\"Mandarin\":\"音乐列表\",\"Japanese\":\"ミュージシャン\"}}",
      "Service Scheduling And Management": "{\"Service Scheduling And Management\":{\"Mandarin\":\"服务调度与管理\",\"Japanese\":\"サービスのスケジューリングと管理\"}}",
      "Teacher": "{\"Teacher\":{\"Mandarin\":\"老师\",\"Japanese\":\"教師\"}}",
      "Calendar": "{\"Calendar\":{\"Mandarin\":\"日历\",\"Japanese\":\"カレンダー\"}}",
      "light.hue_go_1": "{\"light.hue_go_1\":{\"Mandarin\":\"光.hue_go_1\",\"Japanese\":\"light.hue_go_1\"}}",
      "Appointment": "{\"Appointment\":{\"Mandarin\":\"预约\",\"Japanese\":\"予定\"}}",
      "Cast to TV": "{\"Cast to TV\":{\"Mandarin\":\"投射到电视\"}}",
      "Before We Cross The Street": "{\"Before We Cross The Street\":{\"Mandarin\":\"在我们过马路之前\",\"Japanese\":\"通りを渡る前に\"}}",
      "Sam": "{\"Sam\":{\"Mandarin\":\"他自己\",\"Japanese\":\"彼自身\"}}",
      "Toileting Hygiene": "{\"Toileting Hygiene\":{\"Mandarin\":\"如厕卫生\",\"Japanese\":\"トイレの衛生管理\"}}",
      "A Safe Place to Play": "{\"A Safe Place to Play\":{\"Mandarin\":\"一个安全的地方玩\",\"Japanese\":\"安全に遊べる場所\"}}",
      "Service Pattern": "{\"Service Pattern\":{\"Mandarin\":\"服务模式\",\"Japanese\":\"サービスパターン\"}}",
      "Penny and Marie": "{\"Penny and Marie\":{\"Mandarin\":\"佩妮和玛丽\",\"Japanese\":\"ペニーとマリー\"}}",
      "Select Action": "{\"Select Action\":{\"Mandarin\":\"选择动作\",\"Japanese\":\"アクションの選択\"}}",
      "Helmet Rap": "{\"Helmet Rap\":{\"Mandarin\":\"头盔说唱\",\"Japanese\":\"ヘルメットラップ\"}}",
      "Entity ID": "{\"Entity ID\":{\"Japanese\":\"エンティティID\",\"Mandarin\":\"实体编号\"}}",
      "Chinese folk music": "{\"Chinese folk music\":{\"Mandarin\":\"中国民族音乐\",\"Japanese\":\"中国の民俗音楽\"}}",
      "Sample MP4 Video File for Testing": "{\"Sample MP4 Video File for Testing\":{\"Mandarin\":\"用于测试的示例 MP4 视频文件\",\"Japanese\":\"テスト用のサンプル MP4 ビデオ ファイル\"}}",
      "RABI": "{\"RABI\":{\"Mandarin\":\"一半\",\"Japanese\":\"半分\"}}",
      "Buckle Up": "{\"Buckle Up\":{\"Mandarin\":\"系好安全带\",\"Japanese\":\"シートベルトを締める\"}}",
      "General Quiz": "{\"General Quiz\":{\"Mandarin\":\"一般测验\",\"Japanese\":\"一般クイズ\"}}",
      "When ThingleToodle Came to School": "{\"When ThingleToodle Came to School\":{\"Mandarin\":\"当 ThingleToodle 来到学校\",\"Japanese\":\"ThingleToodle が学校に来たとき\"}}",
      "unavailable": "{\"unavailable\":{\"Mandarin\":\"不可用\",\"Japanese\":\"利用不可\"}}",
      "Technology Support": "{\"Technology Support\":{\"Mandarin\":\"技术支持\",\"Japanese\":\"技術サポート\"}}",
      "Bingo": "{\"Bingo\":{\"Mandarin\":\"答对了\",\"Japanese\":\"ビンゴ\"}}",
      "STARTTIMER": "{\"STARTTIMER\":{\"Mandarin\":\"启动定时器\",\"Japanese\":\"スタートタイマー\"}}",
      "Copy Cat": "{\"Copy Cat\":{\"Mandarin\":\"山寨\",\"Japanese\":\"コピーキャット\"}}",
      "心心": "{\"心心\":{\"Mandarin\":\"心心\",\"Japanese\":\"心臓\"}}",
      "Stop": "{\"Stop\":{\"Mandarin\":\"停止\",\"Japanese\":\"ストップ\"}}",
      "Video Call": "{\"Video Call\":{\"Japanese\":\"ビデオ通話\",\"Mandarin\":\"视频电话\"}}",
      "Donimo Addition": "{\"Donimo Addition\":{\"Mandarin\":\"多尼莫加法\",\"Japanese\":\"ドニモ追加\"}}",
      "voice command": "{\"voice command\":{\"Mandarin\":\"语音控制\",\"Japanese\":\"音声コマンド\"}}",
      "Jingle Bell Rock": "{\"Jingle Bell Rock\":{\"Mandarin\":\"铃儿响叮当\",\"Japanese\":\"ジングルベルロック\"}}",
      "Harry": "{\"Harry\":{\"Mandarin\":\"哈利\",\"Japanese\":\"ハリー\"}}",
      "Home": "{\"Home\":{\"Mandarin\":\"家\",\"Japanese\":\"家\"}}",
      "Quizzes": "{\"Quizzes\":{\"Mandarin\":\"测验\"}}",
      "Select a song": "{\"Select a song\":{\"Mandarin\":\"选择一首歌曲\",\"Japanese\":\"曲を選択してください\"}}",
      "Voice Message": "{\"Voice Message\":{\"Mandarin\":\"语音留言\",\"Japanese\":\"ボイスメッセージ\"}}",
      "Scan Devices": "{\"Scan Devices\":{\"Mandarin\":\"扫描设备\",\"Japanese\":\"スキャンデバイス\"}}",
      "Smart Home": "{\"Smart Home\":{\"Mandarin\":\"智能家居\",\"Japanese\":\"スマートホーム\"}}",
      "Call": "{\"Call\":{\"Mandarin\":\"称呼\",\"Japanese\":\"電話\"}}",
      "Emotional Well Being": "{\"Emotional Well Being\":{\"Mandarin\":\"情感福祉\",\"Japanese\":\"精神的な幸福\"}}",
      "localhost": "{\"localhost\":{\"Mandarin\":\"本地主机\",\"Japanese\":\"ローカルホスト\"}}",
      "James": "{\"James\":{\"Mandarin\":\"詹姆士\",\"Japanese\":\"ジェームス\"}}",
      "Row Row Row Your Boat with Lyrics | LIV Kids Nursery Rhymes and Songs | HD": "{\"Row Row Row Your Boat with Lyrics | LIV Kids Nursery Rhymes and Songs | HD\":{\"Mandarin\":\"Row Row Row Your Boat 歌词LIV 儿童童谣和歌曲 |高清\",\"Japanese\":\"Row Row Row Your Boat 歌詞付き | LIV キッズの童謡と童謡 | HD\"}}",
      "Fire Safety Tools": "{\"Fire Safety Tools\":{\"Mandarin\":\"消防安全工具\",\"Japanese\":\"防火用具\"}}",
      "When I'm Feeling Happy by Trace Moroney - Read Aloud.mp3": "{\"When I'm Feeling Happy by Trace Moroney - Read Aloud.mp3\":{\"Mandarin\":\"当我感到快乐时 Trace Moroney - Read Aloud.mp3\",\"Japanese\":\"When I&#39;m Feeling Happy by Trace Moroney - Read Aloud.mp3\"}}",
      "light.hue_white_lamp_2": "{\"light.hue_white_lamp_2\":{\"Mandarin\":\"light.hue_white_lamp_2\",\"Japanese\":\"light.hue_white_lamp_2\"}}",
      "Family Photos & Videos": "{\"Family Photos & Videos\":{\"Japanese\":\"家族の写真\",\"Mandarin\":\"家庭照片\"}}",
      "Select a book": "{\"Select a book\":{\"Mandarin\":\"选择一本书\",\"Japanese\":\"本を選択してください\"}}",
      "Submit": "{\"Submit\":{\"Mandarin\":\"提交\",\"Japanese\":\"送信\"}}",
      "Cast to TV with TV audio": "{\"Cast to TV with TV audio\":{\"Mandarin\":\"通过电视音频投射到电视\",\"Japanese\":\"テレビの音声を使ってテレビにキャストする\"}}",
      "End Call": "{\"End Call\":{\"Mandarin\":\"结束通话\"}}",
      "Cast to TV with Matilda audio": "{\"Cast to TV with Matilda audio\":{\"Mandarin\":\"使用 Matilda 音频投射到电视\",\"Japanese\":\"Matilda オーディオをテレビにキャストする\"}}",
      "MUSICPLAY": "{\"MUSICPLAY\":{\"Mandarin\":\"音乐播放\",\"Japanese\":\"音楽再生\"}}",
      "Log ID": "{\"Log ID\":{\"Mandarin\":\"日志编号\",\"Japanese\":\"ログID\"}}",
      "Add Books": "{\"Add Books\":{\"Mandarin\":\"添加书籍\",\"Japanese\":\"本を追加する\"}}",
      "NEWSREAD": "{\"NEWSREAD\":{\"Mandarin\":\"新闻阅读\",\"Japanese\":\"ニュースリード\"}}",
      "The Very Busy Spider": "{\"The Very Busy Spider\":{\"Mandarin\":\"非常忙碌的蜘蛛\",\"Japanese\":\"とても忙しいクモ\"}}",
      "Track 6": "{\"Track 6\":{\"Mandarin\":\"轨道 6\",\"Japanese\":\"トラック6\"}}",
      "Technology Tools": "{\"Technology Tools\":{\"Mandarin\":\"技术工具\",\"Japanese\":\"テクノロジーツール\"}}",
      "Matlda1": "{\"Matlda1\":{\"Mandarin\":\"玛特达1\",\"Japanese\":\"マテルダ1\"}}",
      "The Ugly Duckling": "{\"The Ugly Duckling\":{\"Mandarin\":\"丑小鸭\",\"Japanese\":\"みにくいアヒルの子\"}}",
      "Phone Call": "{\"Phone Call\":{\"Japanese\":\"電話\",\"Mandarin\":\"电话\"}}",
      "Video Call Recording": "{\"Video Call Recording\":{\"Mandarin\":\"视频通话录音\",\"Japanese\":\"ビデオ通話の録音\"}}",
      "At The Store": "{\"At The Store\":{\"Mandarin\":\"在商店\",\"Japanese\":\"お店で\"}}",
      "Which is it": "{\"Which is it\":{\"Mandarin\":\"是哪一个\",\"Japanese\":\"どれですか\"}}",
      "Add Video": "{\"Add Video\":{\"Mandarin\":\"添加视频\",\"Japanese\":\"ビデオを追加\"}}",
      "Ugly Duckling": "{\"Ugly Duckling\":{\"Mandarin\":\"丑小鸭\",\"Japanese\":\"みにくいアヒルの子\"}}",
      "AUDIOBOOKSEARCH": "{\"AUDIOBOOKSEARCH\":{\"Mandarin\":\"有声读物搜索\",\"Japanese\":\"オーディオブックサーチ\"}}",
      "User ID": "{\"User ID\":{\"Mandarin\":\"用户身份\",\"Japanese\":\"ユーザーID\"}}",
      "Telehealth": "{\"Telehealth\":{\"Mandarin\":\"远程医疗\",\"Japanese\":\"遠隔医療\"}}",
      "Action": "{\"Action\":{\"Mandarin\":\"行动\",\"Japanese\":\"アクション\"}}",
      "Walking Down The Street": "{\"Walking Down The Street\":{\"Mandarin\":\"走在街上\",\"Japanese\":\"ウォーキング・ダウン・ザ・ストリート\"}}",
      "老師": "{\"老師\":{\"Mandarin\":\"老师\",\"Japanese\":\"教師\"}}",
      "Jack's Shopping List": "{\"Jack's Shopping List\":{\"Mandarin\":\"杰克的购物清单\",\"Japanese\":\"ジャックの買い物リスト\"}}",
      "Derek": "{\"Derek\":{\"Mandarin\":\"德里克\",\"Japanese\":\"デレク\"}}",
      "12345 Once I Caught A Fish Alive _ Nursery Rhyme _ Hooplakidz.mp3": "{\"12345 Once I Caught A Fish Alive _ Nursery Rhyme _ Hooplakidz.mp3\":{\"Mandarin\":\"12345 曾经我钓到一条活鱼_童谣_Hooplakidz.mp3\",\"Japanese\":\"12345 生きた魚を捕まえたら _ 童謡 _ Hooplakidz.mp3\"}}",
      "Counting 2 Dollar Coints": "{\"Counting 2 Dollar Coints\":{\"Mandarin\":\"数 2 美元硬币\",\"Japanese\":\"2ドル硬貨を数える\"}}",
      "Yazan": "{\"Yazan\":{\"Mandarin\":\"经过\",\"Japanese\":\"に\"}}",
      "unknown": "{\"unknown\":{\"Mandarin\":\"未知\",\"Japanese\":\"知らない\"}}",
      "媽媽": "{\"媽媽\":{\"Mandarin\":\"妈妈\",\"Japanese\":\"母親\"}}",
      "VideoFile": "{\"VideoFile\":{\"Mandarin\":\"视频文件\",\"Japanese\":\"ビデオファイル\"}}",
      "house that jack built": "{\"house that jack built\":{\"Mandarin\":\"杰克建造的房子\",\"Japanese\":\"ジャックが建てた家\"}}",
      "Books": "{\"Books\":{\"Mandarin\":\"图书\",\"Japanese\":\"本\"}}",
      "Please Enter ID to call": "{\"Please Enter ID to call\":{\"Mandarin\":\"请输入电话号码\",\"Japanese\":\"IDを入力して電話してください\"}}",
      "Audio Call": "{\"Audio Call\":{\"Mandarin\":\"音频通话\",\"Japanese\":\"音声通話\"}}",
      "Receptionist": "{\"Receptionist\":{\"Mandarin\":\"接待员\",\"Japanese\":\"受付\"}}",
      "Scan Cast Devices": "{\"Scan Cast Devices\":{\"Mandarin\":\"扫描投射设备\",\"Japanese\":\"スキャンキャストデバイス\"}}",
      "5 little ducks": "{\"5 little ducks\":{\"Mandarin\":\"5只小鸭子\",\"Japanese\":\"5羽の小さなアヒル\"}}",
      "Head Shoulders Knees & Toes (Sing It).mp3": "{\"Head Shoulders Knees & Toes (Sing It).mp3\":{\"Mandarin\":\"头肩膀膝盖\",\"Japanese\":\"頭 肩 膝\"}}",
      "Date": "{\"Date\":{\"Mandarin\":\"日期\",\"Japanese\":\"日にち\"}}",
      "Brown Bear What Do You See": "{\"Brown Bear What Do You See\":{\"Mandarin\":\"棕熊你看到了什么\",\"Japanese\":\"ヒグマは何を見ますか\"}}",
      "Good Morning Song": "{\"Good Morning Song\":{\"Mandarin\":\"早安歌\",\"Japanese\":\"グッドモーニングソング\"}}",
      "Count and Add": "{\"Count and Add\":{\"Mandarin\":\"计数并添加\",\"Japanese\":\"数えて加算する\"}}",
      "The Wiggles Taba Naba Style! (feat. Christine Anu) Kids Songs.mp3": "{\"The Wiggles Taba Naba Style! (feat. Christine Anu) Kids Songs.mp3\":{\"Mandarin\":\"Wiggles Taba Naba 风格！ (feat. Christine Anu) 儿童歌曲.mp3\",\"Japanese\":\"ウィグルズタバナバスタイル！ (feat. クリスティーン・アヌ) キッズソング.mp3\"}}",
      "Means of Transportation": "{\"Means of Transportation\":{\"Mandarin\":\"交通方式\",\"Japanese\":\"翻訳の意味\"}}",
      "Interaction Logs": "{\"Interaction Logs\":{\"Mandarin\":\"交互日志\",\"Japanese\":\"インタラクションログ\"}}",
      "John": "{\"John\":{\"Mandarin\":\"约翰\",\"Japanese\":\"ジョン\"}}",
      "Scheduler": "{\"Scheduler\":{\"Mandarin\":\"调度器\",\"Japanese\":\"スケジューラ\"}}",
      "Where in the World": "{\"Where in the World\":{\"Mandarin\":\"在世界的哪个地方\",\"Japanese\":\"世界のどこに\"}}",
      "Big rain coming": "{\"Big rain coming\":{\"Mandarin\":\"大雨来了\",\"Japanese\":\"大雨が降る\"}}",
      "isMounted": "YES",
      "Configuration": "{\"Configuration\":{\"Mandarin\":\"配置\",\"Japanese\":\"構成\"}}",
      "peerId": "Caregiver_229a-g",
      "Voice Message listing": "{\"Voice Message listing\":{\"Mandarin\":\"语音留言列表\",\"Japanese\":\"ボイスメッセージリスト\"}}",
      "Brain Training": "{\"Brain Training\":{\"Mandarin\":\"大脑训练\",\"Japanese\":\"脳のトレーニング\"}}",
      "Entertainment": "{\"Entertainment\":{\"Mandarin\":\"娱乐\",\"Japanese\":\"エンターテイメント\"}}",
      "how are you": "{\"how are you\":{\"Mandarin\":\"你好吗\",\"Japanese\":\"元気ですか\"}}",
      "Cheeky Tunes - Children's Music - Ngaya Naba Aboriginal songs for children.mp3": "{\"Cheeky Tunes - Children's Music - Ngaya Naba Aboriginal songs for children.mp3\":{\"Mandarin\":\"Cheeky Tunes - 儿童音乐 - Ngaya Naba 原住民儿童歌曲.mp3\",\"Japanese\":\"Cheeky Tunes - 子供の音楽 - Naya Naba 子供向けのアボリジニの歌.mp3\"}}",
      "The Hold My Hand Rap": "{\"The Hold My Hand Rap\":{\"Mandarin\":\"握住我的手说唱\",\"Japanese\":\"ホールド・マイ・ハンド・ラップ\"}}",
      "STOPTIMER": "{\"STOPTIMER\":{\"Mandarin\":\"停止定时器\",\"Japanese\":\"ストップタイマー\"}}",
      "Health and Fitness": "{\"Health and Fitness\":{\"Mandarin\":\"健康和健身\",\"Japanese\":\"健康と運動\"}}",
      "Add Music": "{\"Add Music\":{\"Mandarin\":\"添加音乐\",\"Japanese\":\"音楽を追加する\"}}",
      "迪迪": "{\"迪迪\":{\"Mandarin\":\"迪迪\",\"Japanese\":\"ディディ\"}}",
      "[object Object]": "{\"[object Object]\":{\"Mandarin\":\"[对象对象]\"}}",
      "Games": "{\"Games\":{\"Mandarin\":\"游戏\",\"Japanese\":\"ゲーム\"}}",
      "Cookie Please": "{\"Cookie Please\":{\"Mandarin\":\"请饼干\",\"Japanese\":\"クッキーください\"}}",
      "Superstar": "{\"Superstar\":{\"Mandarin\":\"超级明星\",\"Japanese\":\"スーパースター\"}}",
      "YOUTUBEPLAYSOLO": "{\"YOUTUBEPLAYSOLO\":{\"Mandarin\":\"YouTube独奏\",\"Japanese\":\"YouTubeプレイソロ\"}}",
      "Photo": "{\"Photo\":{\"Mandarin\":\"照片\",\"Japanese\":\"写真\"}}",
      "A Ride in The Car": "{\"A Ride in The Car\":{\"Mandarin\":\"坐车\",\"Japanese\":\"車に乗って\"}}",
      "Songs": "{\"Songs\":{\"Mandarin\":\"歌曲\",\"Japanese\":\"曲\"}}",
      "Counting": "{\"Counting\":{\"Mandarin\":\"数数\",\"Japanese\":\"数える\"}}",
      "dr froster": "{\"dr froster\":{\"Mandarin\":\"弗罗斯特博士\",\"Japanese\":\"フロスター博士\"}}",
      "Video": "{\"Video\":{\"Mandarin\":\"视频\",\"Japanese\":\"ビデオ\"}}",
      "light.hue_white_lamp_1": "{\"light.hue_white_lamp_1\":{\"Mandarin\":\"light.hue_white_lamp_1\",\"Japanese\":\"light.hue_white_lamp_1\"}}",
      "綠豆": "{\"綠豆\":{\"Mandarin\":\"绿豆\",\"Japanese\":\"サヤインゲン\"}}",
      "Facial Expression": "{\"Facial Expression\":{\"Mandarin\":\"表情\",\"Japanese\":\"表情\"}}",
      "pageLanguage": "English",
      "Friends and Relatives": "{\"Friends and Relatives\":{\"Japanese\":\"友人や親戚\",\"Mandarin\":\"朋友和亲戚\"}}",
      "ROW ROW ROW YOUR BOAT _ Classic Nursery Rhymes _ English Songs For Kids _ Nursery Rhymes TV.mp3": "{\"ROW ROW ROW YOUR BOAT _ Classic Nursery Rhymes _ English Songs For Kids _ Nursery Rhymes TV.mp3\":{\"Mandarin\":\"ROW ROW ROW YOUR BOAT_经典童谣_英文儿歌_童谣TV.mp3\",\"Japanese\":\"ROW ROW ROW YOUR BOAT _ 古典的な童謡 _ 子供のための英語の歌 _ 童謡 TV.mp3\"}}",
      "VIDEOCAST": "{\"VIDEOCAST\":{\"Mandarin\":\"视频广播\",\"Japanese\":\"ビデオキャスト\"}}",
      "[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]": "{\"[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object],[object Object]\":{\"Mandarin\":\"[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象], [对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象],[对象对象]\"}}",
      "Quiz": "{\"Quiz\":{\"Mandarin\":\"测验\",\"Japanese\":\"クイズ\"}}",
      "twinkle star": "{\"twinkle star\":{\"Mandarin\":\"闪烁的星星\",\"Japanese\":\"きらきら星\"}}",
      "Analytics": "{\"Analytics\":{\"Mandarin\":\"分析\",\"Japanese\":\"分析\"}}",
      "Jeff's Bike": "{\"Jeff's Bike\":{\"Mandarin\":\"杰夫的自行车\",\"Japanese\":\"ジェフのバイク\"}}",
      "Time": "{\"Time\":{\"Mandarin\":\"时间\",\"Japanese\":\"時間\"}}",
      "Description": "{\"Description\":{\"Mandarin\":\"描述\",\"Japanese\":\"説明\"}}",
      "YOUTUBESEARCH": "{\"YOUTUBESEARCH\":{\"Mandarin\":\"优酷搜索\",\"Japanese\":\"ユーチューブサーチ\"}}",
      "State": "{\"State\":{\"Japanese\":\"州\",\"Mandarin\":\"状态\"}}",
      "Famous Faces": "{\"Famous Faces\":{\"Mandarin\":\"名人面孔\",\"Japanese\":\"有名な顔\"}}",
      "Service Preference": "{\"Service Preference\":{\"Mandarin\":\"服务偏好\",\"Japanese\":\"サービスの好み\"}}",
      "Exercise": "{\"Exercise\":{\"Mandarin\":\"锻炼\",\"Japanese\":\"エクササイズ\"}}",
      "SELECTSERVICE": "{\"SELECTSERVICE\":{\"Mandarin\":\"选择服务\",\"Japanese\":\"サービスの選択\"}}",
      "Healthy vs Unhealthy": "{\"Healthy vs Unhealthy\":{\"Mandarin\":\"健康与不健康\",\"Japanese\":\"健康と不健康\"}}",
      "Analytics: Service Pattern": "{\"Analytics: Service Pattern\":{\"Mandarin\":\"分析：服务模式\",\"Japanese\":\"分析: サービスパターン\"}}",
      "speech": "{\"speech\":{\"Mandarin\":\"演讲\",\"Japanese\":\"スピーチ\"}}",
      "Child": "{\"Child\":{\"Mandarin\":\"孩子\",\"Japanese\":\"子供\"}}",
      "BOOKLIST": "{\"BOOKLIST\":{\"Mandarin\":\"书单\",\"Japanese\":\"ブックリスト\"}}",
      "Justine Clarke - Dancing Face (Official Video).mp3": "{\"Justine Clarke - Dancing Face (Official Video).mp3\":{\"Mandarin\":\"Justine Clarke - Dancing Face（官方视频）.mp3\",\"Japanese\":\"Justine Clarke - Dancing Face (オフィシャル ビデオ).mp3\"}}",
      "Jack's shopping list": "{\"Jack's shopping list\":{\"Mandarin\":\"杰克的购物清单\",\"Japanese\":\"ジャックの買い物リスト\"}}",
      "Activity": "{\"Activity\":{\"Mandarin\":\"活动\",\"Japanese\":\"アクティビティ\"}}",
      "Mandarin": "{\"Mandarin\":{\"Mandarin\":\"普通话\",\"Japanese\":\"北京語\"}}",
      "Title": "{\"Title\":{\"Mandarin\":\"标题\",\"Japanese\":\"タイトル\"}}",
      "Old MacDonald Had A Farm | Nursery Rhymes | Super Simple Songs": "{\"Old MacDonald Had A Farm | Nursery Rhymes | Super Simple Songs\":{\"Mandarin\":\"老麦克唐纳有一个农场 |儿歌 |超级简单的歌曲\",\"Japanese\":\"マクドナルド老人には農場がありました |童謡 | 童謡スーパーシンプルソング\"}}",
      "Words and Letters": "{\"Words and Letters\":{\"Mandarin\":\"文字和字母\",\"Japanese\":\"言葉と文字\"}}",
      "Penny Ann Marie": "{\"Penny Ann Marie\":{\"Mandarin\":\"潘妮·安·玛丽\",\"Japanese\":\"ペニー・アン・マリー\"}}",
      "Tammy's Day Out": "{\"Tammy's Day Out\":{\"Mandarin\":\"塔米的一天\",\"Japanese\":\"タミーの一日\"}}",
      "Friends And Relatives": "{\"Friends And Relatives\":{\"Mandarin\":\"朋友和亲戚\",\"Japanese\":\"友人や親戚\"}}",
      "userRole": "ADMIN",
      "Talk With Matilda": "{\"Talk With Matilda\":{\"Mandarin\":\"与玛蒂尔达交谈\",\"Japanese\":\"マチルダと話す\"}}",
      "YOUTUBEPLAY": "{\"YOUTUBEPLAY\":{\"Mandarin\":\"优酷播放\",\"Japanese\":\"ユーチューブプレイ\"}}",
      "U3A  Partnerships": "{\"U3A  Partnerships\":{\"Mandarin\":\"U3A 合作伙伴\",\"Japanese\":\"U3A パートナーシップ\"}}",
      "Bring it": "{\"Bring it\":{\"Mandarin\":\"带上它\",\"Japanese\":\"持ってきてください\"}}",
      "ocr": "{\"ocr\":{\"Mandarin\":\"文字识别\",\"Japanese\":\"ocr\"}}",
      "Simon Says Song for Children by Patty Shukla.mp3": "{\"Simon Says Song for Children by Patty Shukla.mp3\":{\"Mandarin\":\"Simon Says Song for Children by Patty Shukla.mp3\",\"Japanese\":\"Simon Says Song for Children by Patty Shukla.mp3\"}}",
      "Analytics: Interactions": "{\"Analytics: Interactions\":{\"Mandarin\":\"分析：交互\",\"Japanese\":\"分析: インタラクション\"}}",
      "Chess": "{\"Chess\":{\"Mandarin\":\"棋\",\"Japanese\":\"チェス\"}}",
      "WebApp": "{\"WebApp\":{\"Mandarin\":\"网络应用程序\",\"Japanese\":\"ウェブアプリ\"}}",
      "undefined": "{\"undefined\":{\"Mandarin\":\"不明确的\"}}",
      "English": "{\"English\":{\"Mandarin\":\"英语\",\"Japanese\":\"英語\"}}",
      "React Data: 1": "{\"React Data: 1\":{\"Mandarin\":\"反应数据：1\",\"Japanese\":\"反応データ: 1\"}}",
      "Last Changed": "{\"Last Changed\":{\"Japanese\":\"最終変更日\",\"Mandarin\":\"最后更改\"}}",
      "Is That True": "{\"Is That True\":{\"Mandarin\":\"真的吗\",\"Japanese\":\"本当\"}}",
      "One, Two, Buckle My Shoe - The Best Songs for Children _ LooLoo Kids.mp3": "{\"One, Two, Buckle My Shoe - The Best Songs for Children _ LooLoo Kids.mp3\":{\"Mandarin\":\"一，二，扣我的鞋 - 最好听的儿童歌曲_LooLoo Kids.mp3\",\"Japanese\":\"One, Two, Buckle My Shoe - The Best Songs for Children _ LooLoo Kids.mp3\"}}",
      "The Very Hungry Caterpillar": "{\"The Very Hungry Caterpillar\":{\"Mandarin\":\"好饿的毛毛虫\",\"Japanese\":\"はらぺこあおむし\"}}",
      "pat a cake": "{\"pat a cake\":{\"Mandarin\":\"拍个蛋糕\",\"Japanese\":\"ケーキをたたく\"}}"
    }
    return data
  }
  const computeTitle = (title) => {
    console.log("COMPUTE TITLE", localStorage.getItem("Connect"), translateData())
    if (localStorage.getItem("Connect") === null) {
      for (let [key, value] of Object.entries(translateData())) {
        console.log(key, value);
        localStorage.setItem(key, value)
      }
    }

    let language = localStorage.getItem("pageLanguage");
    if (language === undefined) {
      language = "English"
      localStorage.setItem("pageLanguage", "English")
    }
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }

    return title
  }
  return (
    <div id="main-menu-panel">
      <TitleBar title={computeTitle("Home")} />

      <div className={classes.root} id="blocks">
        {homeApps.map((app) => (
          <>
            {
              app.title == "Admin" ?
                <>
                  {
                    localStorage.getItem("userRole") == "ADMIN" ?
                      (
                        <ButtonBase
                          onClick={() => appClick(app.title, app.slug)}
                          focusRipple
                          key={app.title}
                          className={classes.image}
                          focusVisibleClassName={classes.focusVisible}
                        >
                          <span className={classes.imageSrc} style={{ background: app.background }}>
                            <svg className={classes.imageSVG}
                              width="24"
                              height="24"
                              strokeWidth="1.5"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              dangerouslySetInnerHTML={{ __html: app.svg }} />
                          </span>
                          <span className={classes.imageButton}>
                            <Typography
                              component="span"
                              variant="subtitle1"
                              color="inherit"
                              className={classes.imageTitle}
                            >
                              {computeTitle(app.title)}
                            </Typography>
                          </span>
                        </ButtonBase>
                      )
                      :
                      <></>
                  }
                </>
                :
                (
                  <ButtonBase
                    onClick={() => appClick(app.title, app.slug)}
                    focusRipple
                    key={app.title}
                    className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                  >
                    <span className={classes.imageSrc} style={{ background: app.background }}>
                      <svg className={classes.imageSVG}
                        width="24"
                        height="24"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        dangerouslySetInnerHTML={{ __html: app.svg }} />
                    </span>
                    <span className={classes.imageButton}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        className={classes.imageTitle}
                      >
                        {computeTitle(app.title)}
                      </Typography>
                    </span>
                  </ButtonBase>
                )
            }
          </>
        ))}

      </div>
      <div>

        {/* <Link to="/profile">
          <Fab className={classes.fab} variant="extended" size="medium" color="primary" aria-label="add">
            <svg style={{ marginRight: '5px' }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
              <path fill="#fff" d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
            </svg>
            Profile
          </Fab>
        </Link> */}

        <Switch>
          {/* <Route exact path={["/", "/home"]} component={Home} /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/profile" component={Profile} />
          <Route path="/user" component={Video} />
          {/* <Route path="/chat" component={Chat} /> */}
          <Route path="/map" component={Map} />
          <Route path="/management" component={RobotStatus} />
          {/* <Route path="/control" component={SwitchLabels} /> */}
          <Route exact path="/music" component={Music} />
          <Route exact path="/youtube" component={YouTube} />
          <Route exact path="/videos" component={YouTubeWithVideo} />
          <Route exact path="/technology-support" component={TechnologySupport} />
          <Route exact path="/voice-command" component={VoiceCommand} />
          <Route exact path="/heart-rate" component={HeartRate} />
          <Route exact path="/monitoring" component={Monitoring} />
          <Route exact path="/receptionist" component={MonitoringV2} />
          <Route exact path="/fitbit" component={Fitbit} />
          <Route exact path="/manage-user" component={UserManage} />
          <Route exact path="/manage-profile" component={ManageProfile} />
          <Route exact path="/manage-robot" component={RobotManage} />
          <Route exact path="/manage-resident" component={ReceptionistCrud} />
          <Route exact path="/manage-conversation" component={Conversation} />
          <Route exact path="/conversation-log" component={ConversationLog} />
          <Route exact path="/reception" component={Reception} />
          <Route exact path="/face-recognition" component={FaceRecognition} />
          <Route exact path="training-module-list" component={TrainingModule} />
          <Route exact path="/health-and-wellbeing" component={HealthAndWellBeing} />
          <Route exact path="/emotional-wellbeing" component={EmotionalWellBeing} />
          <Route exact path="/brain-training" component={BrainTraining} />
          <Route exact path="/bingo-card" component={BingoCard} />
          <Route exact path="/bingo-card-serguis" component={BingoCardSergius} />
          <Route exact path="/number-card-1to20" component={NumberCard1to20} />
          <Route exact path="/friends-and-relatives" component={FriendsAndRelatives} />
          <Route exact path="/service-scheduling" component={ServiceSchedulingAndManagement} />
          <Route exact path="/safety" component={Safety} />
          <Route exact path="/technology-tools" component={TechnologyTools} />
          <Route exact path="/admin" component={AdminPage} />
          <Route exact path="/hobbies" component={Hobbies} />
          <Route exact path="/entertainment" component={Entertainment} />
          <Route exact path="/cookinglist" component={CookingList} />
          <Route exact path="/school" component={School} />
          <Route exact path="/talk-with-matilda" component={TalkWithMatilda} />
          <Route exact path="/audio-call" component={AudioCall} />
          <Route exact path="/exercise" component={ExerciseList} />
          <Route exact path="/new-games" component={NewGames} />
          <Route exact path="/smart-home" component={SmartHome} />
          <Route exact path="/voice-com" component={VoiceCom} />
          <Route path="/book" component={Book} />
          <Route path="/gamelist" component={Games} />
          <Route path="/learninglist" component={LearningList} />
          <Route path="/games" component={Games} />
          <Route path="/memorygame" component={MemoryGame} />
          <Route path="/maths" component={Maths} />
          <Route path="/chess" component={Chess} />
          <Route path="/sudoku" component={Sudoku} />
          <Route path="/scrabble" component={Scrabble} />
          <Route path="/call" component={Call} onLeave={leavingCall} />
          <Route path="/call-receptionist" component={CallReceptionist} />
          <Route path="/callv2/:id" component={CallV2} />
          <Route path="/uTube" component={YouTube} />
          <Route path="/tictactoe" component={TicTacToe} />
          <Route path="/family" component={Family} />
          <Route path="/settings" component={Settings} />
          {/* <Route path="/mod" component={BoardModerator} /> */}
          <Route path="/calendar" component={MyCalendar} />
          <Route path="/analytics" component={Analytics} />
          <Route path="/interaction-logs" component={LogTable} />
          <Route path="/service-pattern" component={ServicePattern} />
          <Route path="/service-preference" component={ServicePreference} />
          <Route path="/translation" component={Translation} />
          <Route path="/face-detection" component={FaceDetection} />
          <Route path="/emotion-recognition" component={EmotionRecognition} />
          <Route path="/system-settings" component={SystemSettings} />
          <Route path="/quiz" component={() => <ImageQuiz quizid={'Famous Faces.quiz'} />} />
          <Route path="/quizlist" component={() => <ImageQuizOrig />} />
          <Route path="/specific-quiz" component={() => <ImageQuiz />} />
          <Route path="/bingolist" component={() => <NewBingo />} />
          <Route path="/wifisetting" component={WifiSetting} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route path="/player/:id" component={Player} />
          <Route path="/news" component={NewsApp} />
          <Route path="/robotblocks" component={RobotBlocks} />
          <Route path="/workflowdesigner" component={WorkflowDesinger} />
          <Route path="/conversation-question" component={ConversationQuestion} />
          <Route path="/command-control" component={CommandControl} />
          <Route path="/messenger" component={Messenger} />
          <Route path="/messenger-audio" component={MessengerAudio} />
        </Switch>

      </div>
    </div>
  );
}
