import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import TranslationService from "../../services/translation.service";
import { socket, vocalise, waitForSocketConnection } from "../../socket";

const styles = (theme) => ({
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    card: {
        marginRight: '10px',
        marginBottom: '10px',
        border: '2px solid red',
        borderRadius: '8px',
        padding: '10px',
        textAlign: 'center',
    },
    firstCard: {
        fontSize: '200px',
        color: 'orange',
    },
    otherCard: {
        fontSize: '72px',
        color: 'orange',
    },
    fixedBorder: {
        border: '2px solid gray',
        borderRadius: '8px',
        padding: '10px',
        textAlign: 'center',
        width: '350px',
        margin: '10px', // Adjust margin as needed
        backgroundColor: 'orange',
        color: 'white',
    },
    fixedBorderSmaller: {
        border: '2px solid gray',
        borderRadius: '8px',
        padding: '10px',
        textAlign: 'center',
        width: '150px',
        margin: '10px', // Adjust margin as needed
    },
});

class NumberCard1to20 extends Component {
    constructor() {
        super();
        this.state = {
            drawnCards: [],
            cardsPerRow: 4, // Number of cards per row
            maxNumber: 20,
        };
        this.computeTitle = this.computeTitle.bind(this);
        this.drawCard = this.drawCard.bind(this);
        this.resetDeck = this.resetDeck.bind(this);
        this.finishGame = this.finishGame.bind(this);
    }

    componentDidMount() {
        const currentUrl = window.location.href;
        let remoteId = "Matilda_229a-g"
        console.log("Mounting ...", currentUrl);
        if (currentUrl.includes("remoteId")) {
            remoteId = currentUrl.split("?")[1].split("=")[1].trim()
            console.log("Mounted ", remoteId)
            localStorage.setItem("remoteId", remoteId)
            this.setState({ remoteId: remoteId});
        }

        // waitForSocketConnection(socket, () => { vocalise("Welcome to bingo service. Let us start playing bingo now.", "neutral", "none", remoteId) });
        var speak = { "api": "vocalise", "text": "Welcome to bingo service. Let us start playing bingo now.", "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
        setTimeout(this.drawCard, 8000)
    }

    computeTitle = (title) => {
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
        }
        return title;
    };

    drawCard = () => {
        let maxNumber = 20;
        const currentUrl = window.location.href;
        let remoteId = 'Matilda_229a-g'

        console.log("Mounting ...", currentUrl);
        if (currentUrl.includes("remoteId")) {
            remoteId = currentUrl.split("?")[1].split("=")[1].trim()
            console.log("Mounted ", remoteId)
            localStorage.setItem("remoteId", remoteId)
            this.setState({ remoteId: remoteId});
        }

        if (this.state.drawnCards.length == maxNumber) {
            // waitForSocketConnection(socket, () => { vocalise("All cards has been drawn. Please re-start the game", "neutral", "none", remoteId) });
            var speak = { "api": "vocalise", "text": "All cards has been drawn. Please re-start the game", "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
            waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
        } else {
            console.log("Remote ", remoteId)
            // Draw a unique number
            let drawnNumber;
            do {
                drawnNumber = Math.floor(Math.random() * maxNumber) + 1;
            } while (this.state.drawnCards.some(card => card.number === drawnNumber));

            const digitArray = drawnNumber.toString().split("").map(Number);
            const concatenatedString = digitArray.join(" ");
            
            if (digitArray.length > 1) {
                // waitForSocketConnection(socket, () => { vocalise(drawnNumber.toString() + ". " + concatenatedString, "neutral", "none", remoteId) });
                var speak = { "api": "vocalise", "text": drawnNumber.toString() + ". " + concatenatedString, "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
                waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
            } else {
                // waitForSocketConnection(socket, () => { vocalise(concatenatedString + ". " + concatenatedString + " on its own", "neutral", "none", remoteId) });
                var speak = { "api": "vocalise", "text": concatenatedString + ". " + concatenatedString + " on its own", "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
                waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
            }

            const drawnCard = {
                number: drawnNumber,
            };

            // Update the state to put the newly drawn card at the front
            this.setState((prevState) => ({
                drawnCards: [drawnCard, ...prevState.drawnCards],
            }));

            setTimeout(this.drawCard, 8000)
        }
    };

    resetDeck = () => {
        waitForSocketConnection(socket, () => { vocalise("Re-starting bingo card game. Please clear up your cards now") });
        this.setState({ drawnCards: []});
    }

    finishGame = () => {
        waitForSocketConnection(socket, () => { vocalise("Thank you for playing bingo with me") });
        this.setState({ drawnCards: []})
    }

    render() {
        const { drawnCards } = this.state;
        const { classes } = this.props;

        return (
            <div id="main-menu-panel">
                {/* <TitleBar title={this.computeTitle("Bingo Cards")} /> */}
                {/* <button onClick={this.drawCard}>Draw Card</button> */}
                {/* <button onClick={this.resetDeck}>Reset</button>
                <button onClick={this.finishGame}>Finish</button> */}
                {drawnCards.length > 0 && (
                    <div>
                        <div className={classes.cardContainer}>
                            {drawnCards.map((card, index) => (
                                index == 0 && <div key={index} className={index === 0 ? classes.firstCard : classes.otherCard}>
                                    <div className={classes.fixedBorder}>
                                        {card.number}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={classes.cardContainer}>
                            {drawnCards.map((card, index) => (
                                index != 0 && <div key={index} className={index === 0 ? classes.firstCard : classes.otherCard}>
                                    <div className={classes.fixedBorderSmaller}>
                                        {card.number}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(NumberCard1to20);
