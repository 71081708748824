import React, { useState, useEffect, useRef } from "react";
import Peer from "peerjs";

/**
 * Peer JS setup using Hooks is based on the following repo:
 * https://github.com/Cleberw3b/peerjs-client
 */

//const peerId = 'Caregiver_c80-g';
const peerId = "Caregiver_aa51";

export default function usePeerV2(addRemoteStream, removeRemoteStream) {
    const peer = useRef(null);
    const [peerConnected, setPeerConnected] = useState(false);
    const createMediaStreamFake = () => {
        return new MediaStream([createEmptyAudioTrack(), createEmptyVideoTrack({ width: 320, height: 240 })]);
    }

    const createEmptyAudioTrack = () => {
        const ctx = new AudioContext();
        const oscillator = ctx.createOscillator();
        const dst = oscillator.connect(ctx.createMediaStreamDestination());
        oscillator.start();
        oscillator.mute = true;
        const track = dst.stream.getAudioTracks()[0];
        return Object.assign(track, { enabled: false });
    }

    const createEmptyVideoTrack = ({ width, height }) => {
        const canvas = Object.assign(document.createElement('canvas'), { width, height });
        canvas.getContext('2d').fillRect(0, 0, width, height);

        const stream = canvas.captureStream();
        const track = stream.getVideoTracks()[0];

        return Object.assign(track, { enabled: false });
    };

    const localstream = createMediaStreamFake()
    useEffect(() => {

        const cleanUp = () => {
            if (peer.current) {
                if (!peer.current.disconnected) {
                    peer.current.disconnect();
                }
                try {
                    if (!peer.current.destroyed) {
                        peer.current.destroy();
                    }
                }
                catch (e) {
                    console.log(e)
                }
                // peer.current = null;
            }
        }

        peer.current = peer.current ? peer.current : new Peer(peerId, { host: 'socket.mymatilda.com.au', port: 9090, path: '/peerpath', secure: true });
        // var conn = peer.current.connect('Caregiver_229a-g1');
        peer.current.on('open', (id) => {
            console.log('Peer opened:', id);
            setPeerConnected(true);
        })

        peer.current.on('connection', (data) => {
            console.log('Peer connected:', data);
        })

        peer.current.on('disconnected', () => {
            console.log("Peer disconnected");
            peer.current.reconnect();
        });

        peer.current.on('close', () => {
            console.log("Peer closed remotely");
            cleanUp()
        });

        peer.current.on('error', (error) => {
            console.log("peer error", error);
            cleanUp()
        });

        // PD, this seems only necessary if other people need to call the webapp.
        // peer.current.on('call', (call) => {
        //     console.log('receiving call from ' + call.peer)

        //     // navigator.mediaDevices.getUserMedia(userMediaConfig)
        //     // .then((stream) => {
        //         // Answer the call with an A/V stream.
        //         call.answer(localstream);

        //         // Play the remote stream
        //         call.on('stream', (remoteStream) => {
        //             console.log('incoming call stream');
        //             addRemoteStream(remoteStream);
        //         });

        //         call.on('close', () => {
        //             console.log("The call has ended");
        //             removeRemoteStream(call.peer);
        //         });

        //         call.on('error', (error) => {
        //             console.log(error);
        //             removeRemoteStream(call.peer);
        //         });
        //     // }).catch(error => { console.log(error); });
        // });

    }, [addRemoteStream, removeRemoteStream])


    return [peer.current, peerId, peerConnected];
}