import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import TitleBar from "../Common/TitleBar";
import TranslationService from "../../services/translation.service";
import { sendMessage, socket, vocalise, waitForSocketConnection } from "../../socket";

const styles = (theme) => ({});

class BingoCard extends Component {
    constructor() {
        super();
        this.state = {
            drawnCards: [],
            cardsPerRow: 4, // Number of cards per row
            remoteId: 'Matilda_229a-g'
        };
        this.computeTitle = this.computeTitle.bind(this);
        this.drawCard = this.drawCard.bind(this);
        this.resetDeck = this.resetDeck.bind(this);
        this.finishGame = this.finishGame.bind(this);
    }

    componentDidMount() {
        const currentUrl = window.location.href;
        let remoteId = "Matilda_229a-g"
        console.log("Mounting ...", currentUrl);
        if (currentUrl.includes("remoteId")) {
            remoteId = currentUrl.split("?")[1].split("=")[1].trim()
            console.log("Mounted ", remoteId)
            localStorage.setItem("remoteId", remoteId)
            this.setState({ remoteId: remoteId});
        }
        // waitForSocketConnection(socket, () => { vocalise("Welcome to bingo service. Let us start playing bingo now.") });
        var speak = { "api": "vocalise", "text": "Welcome to bingo service. Let us start playing bingo now.", "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
        setTimeout(this.drawCard, 8000)
    }

    computeTitle = (title) => {
        let language = localStorage.getItem("pageLanguage");
        if (language !== "English" && language !== undefined) {
            title = TranslationService.translateString(title, language);
        }
        return title;
    };

    drawCard = () => {
        // Replace this with your logic to draw a card
        const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
        const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

        const randomSuit = suits[Math.floor(Math.random() * suits.length)];
        const randomRank = ranks[Math.floor(Math.random() * ranks.length)];

        const drawnCard = {
            suit: randomSuit,
            rank: randomRank,
        };

        // Update the state to include the newly drawn card at the beginning of the array
        this.setState((prevState) => ({
            drawnCards: [drawnCard, ...prevState.drawnCards],
        }));

        let randWord = randomRank
        if (randWord == "A") {
            randWord = "One"
        } else if (randWord == "J") {
            randWord = "Jack"
        } else if (randWord == "Q") {
            randWord = "Queen"
        } else if (randWord == "K") {
            randWord = "King"
        }

        const currentUrl = window.location.href;
        let remoteId = "Matilda_229a-g"
        console.log("Mounting ...", currentUrl);
        if (currentUrl.includes("remoteId")) {
            remoteId = currentUrl.split("?")[1].split("=")[1].trim()
            console.log("Mounted ", remoteId)
            localStorage.setItem("remoteId", remoteId)
            this.setState({ remoteId: remoteId});
        }

        console.log(randWord, randomSuit)
        var speak = { "api": "vocEnRuSe", "phrase": randWord + " of " + randomSuit, "language": "en", "emotion": "neutral", "sound": "none", "name": remoteId };
        waitForSocketConnection(socket, () => { socket.send(JSON.stringify(speak)) });
        // waitForSocketConnection(socket, () => { vocalise("I repeat. " + randWord + " of " + randomSuit) });
        setTimeout(this.drawCard, 30000)
    };

    resetDeck = () => {
        waitForSocketConnection(socket, () => { vocalise("Re-starting bingo card game. Please clear up your cards now") });
        this.setState({ drawnCards: []});
    }

    finishGame = () => {
        waitForSocketConnection(socket, () => { vocalise("Thank you for playing bingo with me") });
        this.setState({ drawnCards: []})
    }

    render() {
        const { drawnCards, cardsPerRow } = this.state;

        return (
            <div id="main-menu-panel">
                {/* <TitleBar title={this.computeTitle("Bingo Cards")} /> */}
                {/* <button onClick={this.drawCard}>Draw Card</button>
                <button onClick={this.resetDeck}>Reset</button>
                <button onClick={this.finishGame}>Finish</button> */}
                {drawnCards.length > 0 && (
                    <div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {drawnCards.map((card, index) => (
                                index === 0 ? <div key={index} style={{ marginRight: '10px', marginBottom: '10px', border: index === 0 ? 'none' : 'none' }}>
                                    <img
                                        src={`images/${card.rank}${card.suit.charAt(0).toUpperCase()}.png`}
                                        style={{ width: index === 0 ? '250px' : '100px', height: index === 0 ? '300px' : '150px' }}
                                    />
                                </div> : ''
                            ))}
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {drawnCards.map((card, index) => (
                                index !== 0 ? <div key={index} style={{ marginRight: '10px', marginBottom: '10px', border: index === 0 ? '2px solid red' : 'none' }}>
                                    <img
                                        src={`images/${card.rank}${card.suit.charAt(0).toUpperCase()}.png`}
                                        style={{ width: index === 0 ? '250px' : '100px', height: index === 0 ? '300px' : '150px' }}
                                    />
                                </div> : ''
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(BingoCard);
