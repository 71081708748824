
import React, { Component, useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import styled from 'styled-components'
import { useTable } from 'react-table'
import { Input } from "reactstrap";
import { Link, useHistory } from 'react-router-dom';
import { sendMessage, socket, name, waitForSocketConnection, vocalise } from '../../socket';
import Button from '@material-ui/core/Button';
import TitleBar from '../Common/TitleBar';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import TranslationService from "../../services/translation.service";
import axios from "axios";


const Styles = styled.div`
  padding: 0rem;

  table {
    width:100%;
    border-collapse: separate;
    border-spacing: 5px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th{
      margin: 0;
      padding: 10px 10px;
      background: #ccc;
      border-radius: 10px;
      font-size: 16px;
    }
    td {
      margin: 0;
      padding: 3px 12px;
      background: #fff;
      font-size: 16px;
      color:#6e6e6e;
      border-radius: 10px;
      :last-child {
        border-right: 0;
      }
    }
  }
`

const computeTitle = (title) => {
  console.log("COMPUTE TITLE")
  let language = localStorage.getItem("pageLanguage");
  if (language !== "English" && language !== undefined) {
    title = TranslationService.translateString(title, language);
    console.log("TITLE ", title)
  }
  return title
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {/* {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')} Action</th>
            ))}
          </tr>
        ))} */}
        <tr>
          <th>{computeTitle('Songs')}</th>
          <th>{computeTitle('Action')}</th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Music = () => {

  const [gotSongs, setGotSongs] = useState(false);
  const [songs, setSongs] = useState([]);
  const [currentPlay, setCurrentPlay] = useState("Select a song");
  const [isYoutube, setIsYoutube] = useState(false);
  const [ytData, setYtData] = useState([]);
  let history = useHistory();
  useEffect(() => {

    let name = localStorage.getItem('remoteId');
    if (!name) {
      history.push('/login')
    }
    let isMounted = false; // note this flag denote mount status
    if (socket.readyState === socket.OPEN) {
      // open
      console.log("SOCKET STATE ", socket.readyState)
    } else {
      console.log("SOCKET STATE ", socket.readyState)
      // let socket2 = new W3CWebSocket('wss://socket.mymatilda.com.au:3389');
      // waitForSocketConnection( socket2 , () => { console.log("OK NOW") } )
    }
    if (!gotSongs) {

      var api = `{"api":"music", "action":"get","name": "${name}"}`;
      waitForSocketConnection(socket, () => { sendMessage(api) })
      // waitForSocketConnection( socket , () => { vocalise("HELLO I AM MATILDA")  } )

    }
    socket.onmessage = (message) => {

      console.log("ONMESSAGE ", message.data)
      if ((message.data) && (message.data.toString().includes("music")) && (message.data.toString().includes("start")) && (!message.data.toString().includes("startEmoREco"))) {
        let obj = JSON.parse(message.data);
        console.log("PLAYING ", obj.file.split("/")[3])
        setCurrentPlay("Playing " + obj.file.split("/")[3])
      }
      if ((message.data) && (message.data.toString().includes("setYouTubeStart"))) {
        let obj = JSON.parse(message.data);
        setCurrentPlay("Playing " + obj.url)
      }
      if ((message.data) && (message.data.toString().includes("songs"))) {
        var str = message.data.substring(0, message.data.length - 1);
        console.log("RECEIVED", str)
        if (str.charAt(str.length - 1) !== '}') {
          str = message.data;
      }
        try {
          //var obj = JSON.parse(str.replace(/\0/g, ''));
          var obj = str.replace(/\0/g, '');

          obj = JSON.parse(obj);
          console.log("===========")
          console.log(obj)
          console.log("===========")
          console.log(typeof (obj))
          console.log("===========")
          console.log(obj['type']);
          console.log("===========")
          console.log(obj['songlist']);

          if (!isMounted && obj.type == "songs") {
            setGotSongs(true);
            setSongs(transformData(obj.songlist));
            console.log(obj.songlist);
          }
          return () => {
            isMounted = true;
          };
        } catch (e) {
          console.log(e)
          return false;
        }
      }



    };
  }, [gotSongs, songs]);

  const transformData = (obj) => {
    let finalObject = []
    for (let i = 0; i < obj.length; i++) {
      obj[i]["title"] = computeTitle(obj[i]["title"])
      finalObject.push(obj[i])
    }
    return obj
  }

  const handleStartClick = (e) => {
    //
    let currentTarget = e.currentTarget.value;
    let gotTitle = e.currentTarget.value.split("/")[3];
    console.log("Handle Start Click ", gotTitle);
    // alert("You selected " + gotTitle);

    let data = JSON.stringify({
      "title": gotTitle
    });

    let config = {
      method: 'post',
      url: 'https://cloud-api-389807.ue.r.appspot.com/music-genre',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        let collection = ["Children", "Classical", "Reggae", "Country", "Funk", "Jazz", "Rock"]
        let motionName = "M" + response.data;
        if (!collection.includes(response.data)) {
          motionName = "MReggae"
        }
        let name = localStorage.getItem('remoteId');
        var api = `{"api":"music", "action":"start", "file":"${currentTarget}", "motion":"DBlues1" , "name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) })

        var api2 = `{"api":"startEmoREco", "service":"music", "name": "${name}", "robot_id": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api2) })

        UserService.postlog("WebApp", "Music", e.currentTarget.value)
          .then((response) => { },
            (error) => { }
          )
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleStartClickYT = (e) => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"setYouTubeStart", "url": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
  }

  const handleStopClickYT = (e) => {
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"setYouTubeStop", "url": "${e.currentTarget.value}", "action":"get","name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) });
    setCurrentPlay("Select a song");
  }

  const handleStopClick = (e) => {
    //alert(e.currentTarget.value);
    let name = localStorage.getItem('remoteId');
    var api = `{"api":"music", "action":"stop", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api) })

    var api2 = `{"api":"stopEmoREco", "service":"music", "name": "${name}"}`;
    waitForSocketConnection(socket, () => { sendMessage(api2) })
    setCurrentPlay("Select a song");
  }

  const columns = React.useMemo(
    () => [
      {
        accessor: 'title',
      },
      {
        accessor: 'path',
        Cell: ({ cell }) => (
          <div>
            <Button size="small" color="primary" value={cell.row.values.path} onClick={handleStartClick}>
              <svg style={{ marginRight: '3px' }} width="18" height="18" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              Play
            </Button>
            <Button size="small" color="secondary" value={cell.row.values.path} onClick={handleStopClick}>
              Stop
            </Button>
          </div>
        )
      },
    ],
    []
  )

  const ytColumns = React.useMemo(
    () => [
      {
        accessor: 'title',
      },
      {
        accessor: 'url',
        Cell: ({ cell }) => (
          <div>
            <Button size="small" color="primary" value={cell.row.values.url} onClick={handleStartClickYT}>
              <svg style={{ marginRight: '3px' }} width="18" height="18" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              Play
            </Button>
            <Button size="small" color="secondary" value={cell.row.values.url} onClick={handleStopClickYT}>
              Stop
            </Button>
          </div>
        )
      },
    ],
    []
  )
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const computeTitle = (title) => {
    console.log("COMPUTE TITLE")
    let language = localStorage.getItem("pageLanguage");
    if (language !== "English" && language !== undefined) {
      title = TranslationService.translateString(title, language);
      console.log("TITLE ", title)
    }
    return title
  }

  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const handleSaveMusic = () => {
    console.log('data ', title, url, name);
    let data = JSON.stringify({
      "robot_id": name,
      "title": title,
      "url": url
    });

    let config = {
      method: 'post',
      url: 'https://cloud-api-389807.ue.r.appspot.com/youtube-music?robot_id=' + name,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setOpen(false);
        setTitle('');
        setUrl('');
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        var api = `{"api":"syncYTMusic", "action":"get","name": "${name}"}`;
        waitForSocketConnection(socket, () => { sendMessage(api) })
      });
  }

  const handleToLocal = () => {
    setIsYoutube(false)
  }

  const handleToYoutube = () => {
    let config = {
      method: 'get',
      url: 'https://cloud-api-389807.ue.r.appspot.com/youtube-music?robot_id=' + name,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.data));
        setIsYoutube(true)
        setYtData(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <>
      <TitleBar title={computeTitle("Music")} parentNode={"/entertainment"} />
      {
        isYoutube ?
          <>
            <Button size="small" variant="outlined" color="primary" onClick={handleClickOpen}>
              {computeTitle('Add YouTube Music')}
            </Button>
            <Button size="small" variant="outlined" color="primary" onClick={handleToLocal}>Switch to Local Music</Button>
          </>
          :
          <>
            <Button size="small" variant="outlined" color="primary" onClick={handleToYoutube}>Switch to YouTube Music</Button>
          </>
      }
      <br /><i><small>{computeTitle(currentPlay)}</small></i>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add YouTube Music</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            helperText="Please add your music Title"
            fullWidth
            value={title}
            onChange={e => { setTitle(e.target.value); }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Music Link"
            type="text"
            helperText="Please add the music links from YouTube, Dailymotion, or any links"
            fullWidth
            value={url}
            onChange={e => { setUrl(e.target.value); }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveMusic} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Styles>
        {
          isYoutube ?
            <Table columns={ytColumns} data={ytData} />
            :
            <Table columns={columns} data={songs} />
        }
      </Styles>
    </>
  );
}

export default Music;